
import React, { createContext } from "react";
import { Datos, Person } from '../data/Datos';
import { createTheme } from '@mui/material/styles';
import { myConfig } from '../data/config'
import { basicgetvisitax, basicpostvisitax, basicputvisitax } from "./api/api";
import { Troubleshoot } from "@mui/icons-material";

export const Context = createContext();
export const ContextProvider = ({ children }) => {
    //const Sandbox = (process.env.NODE_ENV === 'development');
    const Sandbox = false;
    const primaytheme = createTheme({
        palette: {
            primary: {
                main: '#971243',
            },
            secondary: {
                main: '#dc004e', // color secundario
            }
        },
    });
    const [Data, setData] = React.useState({ Datos });
    const [userid, setID] = React.useState(0);
    const [file, setFile] = React.useState(null);
    const [voucherImage,setVoucherImage]=React.useState("");
    const [comprobante, setComprobante] = React.useState("");
    const [lenguage, setLenguage] = React.useState(0);
    const [configurations, setConfigs] = React.useState(myConfig);
    const [TestPaymentMode, setTestPaymentMode] = React.useState(0);
    const [LoggedUser, setLoggedUser] = React.useState(null);
    const [limitvisitors, setlimitvisitors] = React.useState(10)
    const [token, setToken] = React.useState(LoggedUser != null ? LoggedUser.token : "");
    const [card, setcard] = React.useState({
        holder_name: "",
        card_number: "",
        email: "",
        phone_number: "",
        expiration_month: "",
        expiration_year: "",
        cvv2: "",
    },)
    const [paymentAmounts, setPaymentAmounts] = React.useState({ taxesamount: 0, servicecharge: 0 })
    const [ServiceChargeActive, setServiceChargeActive] = React.useState(false)
    const [contact, setContact] = React.useState({ adress: "", email: "", phone: "" })

    React.useEffect(() => {
        setToken(LoggedUser != null ? LoggedUser.token : "");
    }, [LoggedUser])


    let Visitors = []
    try {
        Visitors = [...Data.Datos.detalles];
    } catch (e) { console.log(e) }


    const url1 = process.env.REACT_APP_VISITAX_URL1;
    const url2 = process.env.REACT_APP_VISITAX_URL2;
    const url3 = process.env.REACT_APP_VISITAX_URL3;
    const url4 = process.env.REACT_APP_VISITAX_URL4;
    const url5 = process.env.REACT_APP_VISITAX_URL5;
    const url6 = process.env.REACT_APP_VISITAX_URL6;
    const url7 = process.env.REACT_APP_VISITAX_URL7;
    const url8 = process.env.REACT_APP_VISITAX_URL8;
    const url9 = process.env.REACT_APP_VISITAX_URL9;
    const url10 = process.env.REACT_APP_VISITAX_URL10;
    const url11 = process.env.REACT_APP_VISITAX_URL11;
    const url12 = process.env.REACT_APP_VISITAX_URL12;
    const url13 = process.env.REACT_APP_VISITAX_URL13;
    const url14 = process.env.REACT_APP_VISITAX_URL14;
    const url15 = process.env.REACT_APP_VISITAX_URL15;
    const url16 = process.env.REACT_APP_VISITAX_URL16;
    const url17 = process.env.REACT_APP_VISITAX_URL17;
    const url18 = process.env.REACT_APP_VISITAX_URL18;
    const url19 = process.env.REACT_APP_VISITAX_URL19;
    const url20 = process.env.REACT_APP_VISITAX_URL20;
    const url21 = process.env.REACT_APP_VISITAX_URL21;
    const url22 = process.env.REACT_APP_VISITAX_URL22;
    const url23 = process.env.REACT_APP_VISITAX_URL23;
    const url24 = process.env.REACT_APP_VISITAX_URL24;
    const url25 = process.env.REACT_APP_VISITAX_URL25;
    const url26 = process.env.REACT_APP_VISITAX_URL26;
    const url27 = process.env.REACT_APP_VISITAX_URL27;
    const url28 = process.env.REACT_APP_VISITAX_URL28;
    const url29 = process.env.REACT_APP_VISITAX_URL29;
    const url30 = process.env.REACT_APP_VISITAX_URL30;
    const url31 = process.env.REACT_APP_VISITAX_URL31;
    const url32 = process.env.REACT_APP_VISITAX_URL32;
    const url33 = process.env.REACT_APP_VISITAX_URL33;
    const url34 = process.env.REACT_APP_VISITAX_URL34;
    const url35 = process.env.REACT_APP_VISITAX_URL35;
    const url36 = process.env.REACT_APP_VISITAX_URL36;
    const url37 = process.env.REACT_APP_VISITAX_URL37;
    const url38 = process.env.REACT_APP_VISITAX_URL38;
    const url39 = process.env.REACT_APP_VISITAX_URL39;
    const url40 = process.env.REACT_APP_VISITAX_URL40;
    const url41 = process.env.REACT_APP_VISITAX_URL41;
    const url42 = process.env.REACT_APP_VISITAX_URL42;
    
    const urlkushki = process.env.REACT_APP_VISITAX_KUSHKI;
    let cost = configurations.price;
    let precioUMA = configurations.umaValue;


    ////APIS ROUTINES

    const onOKConfigurations = (response) => {
        let price = "";
        let umaValue = ""
        let idiomas = "";
        let lenguages = "";
        let flags = "";
        let txt = myConfig.txt;
        let contactdata = { ...contact }

        for (let i = 0; i < response.data.data.length; i++) {
            if (response.data.data[i].concept.includes('TaxVisitax')) {
                price = response.data.data[i].configurationInfo;
            }
            if (response.data.data[i].concept.includes('UMA')) {
                umaValue = response.data.data[i].configurationInfo;
            }
            if (response.data.data[i].concept.includes('Languages')) {
                idiomas = JSON.parse(response.data.data[i].configurationInfo);
                lenguages = idiomas.languages;
                flags = idiomas.flags;
                txt = idiomas.txt;
            }
            if (response.data.data[i].concept.includes('SupportE-mail')) {
                contactdata = { ...contactdata, email: response.data.data[i].configurationInfo }
            }
            if (response.data.data[i].concept.includes('SupportNumber')) {
                contactdata = { ...contactdata, phone: response.data.data[i].configurationInfo }
            }
            if (response.data.data[i].concept.includes('AddressDR')) {
                contactdata = { ...contactdata, adress: response.data.data[i].configurationInfo }
            }
            if (response.data.data[i].concept.includes('PaymentTest')) {
                setTestPaymentMode(Number(response.data.data[i].configurationInfo));
            }
            if (response.data.data[i].concept.includes('LimitOfVisitors')) {
                setlimitvisitors(Number(response.data.data[i].configurationInfo));
            }
        }
        setContact(contactdata);
        const newConfigs = {
            umaValue: umaValue,
            price: price,
            lenguages: lenguages,
            flags: flags,
            txt: txt,
            allconfigArray: response.data.data
        }

        response.data.data.map(value => {
            if (value.concept.includes("ServiceCharge")) {
                setPaymentAmounts(prev => ({
                    taxesamount: prev.taxesamount,
                    servicecharge: Number(value.configurationInfo)
                }));
            }
            if (value.concept.includes("ServiceChargeActive")) {

                setServiceChargeActive(value.configurationInfo);
            }
        })
        setConfigs(newConfigs)
    }
    const onFailConfigurations = (response) => {
    }

    const getReceipt = (PassportNumber, setWaitingResponse, OKCallback, FailCallback) => {

        basicgetvisitax(url1 + "?pasaporte=" + PassportNumber, (response) => {
            setWaitingResponse(false);
            GettingResponse(response, OKCallback, FailCallback);
        }, token, Sandbox);
    }
    const getconfigurations = (upload = false) => {
        if (configurations.umaValue == null || upload === true) {
            basicgetvisitax(url3, (response) => {
                GettingResponse(response, onOKConfigurations, onFailConfigurations);
            }, token, Sandbox);
        }
    }

    const getOCR = (setWaitingResponse, OKCallback, FailCallback, imageData) => {
        basicpostvisitax(url2, imageData, (response) => {
            setWaitingResponse(false);
            GettingResponse(response, OKCallback, FailCallback);
        }, token, Sandbox)
    }

    const getFilterData = (OKCallback, FailCallback, data, reports) => {
        basicgetvisitax(reports == true ? url14 + data : url5 + data, (response) => {
            GettingResponse(response, OKCallback, FailCallback);
        }, token, Sandbox);
    }
    const getTransactionDetails = (OKCallback, FailCallback, data,reports) => {
        basicgetvisitax(reports == true ? url15 + data : url39 + data, (response) => {
            GettingResponse(response, OKCallback, FailCallback);
        }, token, Sandbox);
    }

    const getUsers = (OKCallback, FailCallback) => {
        basicgetvisitax(url6, (response) => {
            GettingResponse(response, OKCallback, FailCallback);
        }, token, Sandbox);
    }
    const getSumData = (OKCallback, FailCallback, data) => {
        basicgetvisitax(url7 + data, (response) => {
            GettingResponse(response, OKCallback, FailCallback);
        }, token, Sandbox);
    }
    const getIncidents = (OKCallback, FailCallback) => {
        basicgetvisitax(url10, (response) => {
            GettingResponse(response, OKCallback, FailCallback);
        }, token, Sandbox);
    }
    const getTransactionUser = (OKCallback, FailCallback, data, all) => {
        basicgetvisitax(all == true ? url12 + data : url11 + data, (response) => {
            GettingResponse(response, OKCallback, FailCallback);
        }, token, Sandbox);
    }
    const getTerminals = (OKCallback, FailCallback, data) => {
        basicgetvisitax(url16 + data, (response) => {
            GettingResponse(response, OKCallback, FailCallback);
        }, token, Sandbox);
    }
    const getTransactionUserRecords  = (OKCallback, FailCallback, data) => {
        basicgetvisitax(url22 + data, (response) => {
            GettingResponse(response, OKCallback, FailCallback);
        }, token, Sandbox);
    }
    const getFirebaseConfig = (OKCallback, FailCallback)=>{
        basicgetvisitax(url23, (response) => {
            GettingResponse(response, OKCallback, FailCallback);
        }, token,Sandbox)
    }
    const getRefund = (OKCallback, FailCallback)=>{
        basicgetvisitax(url27, (response) => {
            GettingResponse(response, OKCallback, FailCallback);
        }, token,Sandbox)
    }
    const getPhysicalPaymentRecord = (OKCallback, FailCallback,data="")=>{
        basicgetvisitax(url24+data, (response) => {
            GettingResponse(response, OKCallback, FailCallback);
        }, token,Sandbox)
    }

    const getPhysicalPaymentRecordCompleted = (OKCallback, FailCallback,data)=>{
        basicgetvisitax(url25+data, (response) => {
            GettingResponse(response, OKCallback, FailCallback);
        }, token,Sandbox)
    }

    const getOpenPayReport = (OKCallback, FailCallback,complementary)=>{
        basicgetvisitax(url28+complementary, (response) => {
            GettingResponse(response, OKCallback, FailCallback);
        }, token,Sandbox)
    }

    const getLotClousers = (OKCallback, FailCallback,complementary)=>{
        basicgetvisitax(url29+complementary, (response) => {
            GettingResponse(response, OKCallback, FailCallback);
        }, token,Sandbox)
    }


    const getPreTransactions = (OKCallback, FailCallback, complementary,isfiltered) => {
    
        basicgetvisitax(!isfiltered==true?url42 + complementary:url32+complementary, (response) => {
            GettingResponse(response, OKCallback, FailCallback);
        }, token, Sandbox);
    }

    const getSums = (OKCallback, FailCallback, complementary) => {
        basicgetvisitax(url33 + complementary, (response) => {
            GettingResponse(response, OKCallback, FailCallback);
        }, token, Sandbox);
    }
    const getClosingDays = (OKCallback, FailCallback, complementary) => {
        basicgetvisitax(url36 + complementary, (response) => {
            GettingResponse(response, OKCallback, FailCallback);
        }, token, Sandbox);
    }
    const SerchByVoucher = (OKCallback, FailCallback, complementary) => {
        basicgetvisitax(url37 + complementary, (response) => {
            GettingResponse(response, OKCallback, FailCallback);
        }, token, Sandbox);
    }

    const SerchByPassport = (OKCallback, FailCallback, complementary) => {
        basicgetvisitax(url38 + complementary, (response) => {
            GettingResponse(response, OKCallback, FailCallback);
        }, token, Sandbox);
    }
    const SerchPretransaction = (OKCallback, FailCallback, complementary) => {
        basicgetvisitax(url41 + complementary, (response) => {
            GettingResponse(response, OKCallback, FailCallback);
        }, token, Sandbox);
    }
    const postchargeop3ds = (OKCallback, FailCallback, Data) => {
        basicpostvisitax(url18, Data, (response) => {
            GettingResponse(response, OKCallback, FailCallback);
        }, token, null, Sandbox)
    }
    const postLogin = (setWaitingResponse, OKCallback, FailCallback, Datos) => {
        basicpostvisitax(url4, Datos, (response) => {
            setWaitingResponse(false);
            GettingResponse(response, OKCallback, FailCallback);
        }, token, null, Sandbox)
    }
    const postChangePassword = (OKCallback, FailCallback, Datos) => {
        basicpostvisitax(url8, Datos, (response) => {
            GettingResponse(response, OKCallback, FailCallback);
        }, token, null, Sandbox)
    }
    const postResetPassword = (OKCallback, FailCallback, username) => {
        const urlWithParams = `${url9}?username=${encodeURIComponent(username)}`;
        basicpostvisitax(urlWithParams, {}, (response) => {
            GettingResponse(response, OKCallback, FailCallback);
        }, token, null, Sandbox)
    }
    const postUser = (OKCallback, FailCallback, Datos) => {
        basicpostvisitax(url6, Datos, (response) => {
            GettingResponse(response, OKCallback, FailCallback);
        }, token, null, Sandbox)
    }
    const postCompleteTransaction = (OKCallback, FailCallback, Datos) => {
        basicpostvisitax(url13 + "/" + Datos, Datos, (response) => {
            GettingResponse(response, OKCallback, FailCallback);
        }, token, null, Sandbox)
    }

    const postKushkiToken = (OKCallback, FailCallback, Datos, headers) => {
        basicpostvisitax(urlkushki, Datos, (response) => {
            GettingResponse(response, OKCallback, FailCallback);
        }, token, headers, Sandbox);
    }
    const postForwardEmail = (OKCallback, FailCallback, Datos) => {
        basicpostvisitax(url21, Datos, (response) => {
            GettingResponse(response, OKCallback, FailCallback);
        }, token,null, Sandbox)
    }

    const postUploadOpenPayExcel = (OKCallback, FailCallback, Datos) => {
        basicpostvisitax(url28, Datos, (response) => {
            GettingResponse(response, OKCallback, FailCallback);
        }, token,null, Sandbox)
    }


    const postLotClosures = (OKCallback, FailCallback, Datos) => {
        basicpostvisitax(url29, Datos, (response) => {
            GettingResponse(response, OKCallback, FailCallback);
        }, token,null, Sandbox)
    }

    const postPreTransaction = (OKCallback, FailCallback, Datos) => {
        basicpostvisitax(url30, Datos, (response) => {
            GettingResponse(response, OKCallback, FailCallback);
        }, token,null, Sandbox)
    }

    const postValidateUser = (OKCallback, FailCallback, Datos) => {
        basicpostvisitax(url34, Datos, (response) => {
            GettingResponse(response, OKCallback, FailCallback);
        }, token,null, Sandbox)
    }
    

    const postCompletePreTransaction = (OKCallback, FailCallback, Datos) => {
        basicpostvisitax(url31+Datos, Datos, (response) => {
            GettingResponse(response, OKCallback, FailCallback);
        }, token,null, Sandbox)
    }
    const postClosingDate = (OKCallback, FailCallback, Datos) => {
        basicpostvisitax(url35, Datos, (response) => {
            GettingResponse(response, OKCallback, FailCallback);
        }, token,null, Sandbox)
    }

    const putUpdatePaymentGateway = (OKCallback, FailCallback, Datos) => {
        basicputvisitax(url40, Datos, (response) => {
            GettingResponse(response, OKCallback, FailCallback);
        }, token, Sandbox)
    }
    const putConfigurations = (OKCallback, FailCallback, Datos) => {
        basicputvisitax(url3, Datos, (response) => {
            GettingResponse(response, OKCallback, FailCallback);
        }, token, Sandbox)
    }

    const putUser = (OKCallback, FailCallback, Datos) => {
        basicputvisitax(url6 + "/" + Datos.id, Datos, (response) => {
            GettingResponse(response, OKCallback, FailCallback);
        }, token, Sandbox)
    }
    const putNewPayload = (OKCallback, FailCallback, Datos)=>{
        basicputvisitax(url20 + "/" + Datos.transactionid, Datos.payload, (response) => {
            GettingResponse(response, OKCallback, FailCallback);
        }, token, Sandbox)
    }
    const putPhysicalRecords = (OKCallback, FailCallback, Datos)=>{
        basicputvisitax(url24 , Datos, (response) => {
            GettingResponse(response, OKCallback, FailCallback);
        }, token, Sandbox)
    }
    const putMoveToRefunds = (OKCallback, FailCallback, Datos)=>{
        basicputvisitax(url26+'/'+Datos , Datos, (response) => {
            GettingResponse(response, OKCallback, FailCallback);
        }, token, Sandbox)
    }
    const GettingResponse = (response, OKCallback, FailCallback) => {
        try {
            if (response.status === 200 || response.status === 201) {
                OKCallback(response);
            } else {
                FailCallback(response);
            }
        } catch {
            FailCallback(response.response);
        }

    }


    ////UPDATES ROUTINES
    const UpdateData = (obj) => {
        setData({
            ...Data,
            Datos: obj
        })
    }


    const updateTraveldataobj = (obj) => {
        UpdateData(obj)
    }



    const UpdateVisitors = (NumberofVisitors) => {

        const NewVisitors = [];
        let cost = configurations.price === null ? 1 : configurations.price
        let precioUMA = configurations.umaValue === null ? 1 : configurations.umaValue
        //let importe = Math.round((cost * precioUMA * NumberofVisitors) + ((ServiceChargeActive == 1 && Data.Datos.terminal.includes("T")) ? paymentAmounts.servicecharge : 0));
        let importe = Math.ceil((cost * precioUMA * NumberofVisitors) + (((ServiceChargeActive == 1) ? paymentAmounts.servicecharge : 0) * NumberofVisitors));
        let taxamount = 0;
        const importe_ind = Math.ceil(importe / NumberofVisitors);
        console.log(importe / NumberofVisitors.length);
        console.log(importe_ind);

        importe = 0
        for (let i = 0; i < NumberofVisitors; i++) {
            NewVisitors.push(Person);
        };
        const VisitorsObjt = [...NewVisitors];
        VisitorsObjt.map((valor, indice) => {

            VisitorsObjt[indice].fecha_salida = Data.Datos.fecha_salida;
            VisitorsObjt[indice].importe = importe_ind-paymentAmounts.servicecharge;
            importe += importe_ind;
        })
        //taxamount = importe - ((ServiceChargeActive == 1 && Data.Datos.terminal.includes("T")) ? paymentAmounts.servicecharge : 0);
        taxamount = importe - (((ServiceChargeActive == 1) ? paymentAmounts.servicecharge : 0) * NumberofVisitors);

        if (TestPaymentMode && LoggedUser != null) importe = 1;
        setPaymentAmounts(prev => ({
            taxesamount: taxamount,
            servicecharge: prev.servicecharge
        }));

        updateTraveldataobj({
            ...Data.Datos,
            detalles: VisitorsObjt,
            importe: importe,
            personas: NumberofVisitors,
            //service_charge: ((ServiceChargeActive == 1 && Data.Datos.terminal.includes("T")) ? paymentAmounts.servicecharge : 0)
            service_charge: (((ServiceChargeActive == 1) ? paymentAmounts.servicecharge : 0) * NumberofVisitors)
        });

    }

    const UpdateUsers = (position) => {
        if (position === null) Visitors.push(Person);
        else Visitors.splice(position, 1);
        cost = cost === null ? 1 : cost
        precioUMA = cost === null ? 1 : precioUMA
        //let importe = Math.round((cost * precioUMA * Visitors.length) + ((ServiceChargeActive == 1 && Data.Datos.terminal.includes("T")) ? paymentAmounts.servicecharge : 0));
        let importe = Math.ceil((cost * precioUMA * Visitors.length) + (((ServiceChargeActive == 1) ? paymentAmounts.servicecharge : 0)) * Visitors.length);
        let taxamount = 0;
        const importe_ind = Math.ceil(importe / Visitors.length);
        console.log(importe / Visitors.length);
        console.log(importe_ind);
        const VisitorsObjt = [...Visitors];
        importe = 0;
        Visitors.map((valor, indice) => {
            VisitorsObjt[indice].importe = importe_ind-paymentAmounts.servicecharge;
            importe += importe_ind;
        })
        //taxamount = importe - ((ServiceChargeActive == 1 && Data.Datos.terminal.includes("T")) ? paymentAmounts.servicecharge : 0);
        taxamount = importe - (((ServiceChargeActive == 1) ? paymentAmounts.servicecharge : 0) * Visitors.length);

        setPaymentAmounts(prev => ({
            taxesamount: taxamount,
            servicecharge: prev.servicecharge
        }));
        UpdateData(
            {
                ...Data.Datos,
                detalles: Visitors,
                importe: importe,
                personas: Visitors.length,
                //service_charge: ((ServiceChargeActive == 1 && Data.Datos.terminal.includes("T")) ? paymentAmounts.servicecharge : 0)
                service_charge: (((ServiceChargeActive == 1) ? paymentAmounts.servicecharge : 0) * Visitors.length)

            }
        );
    }


    const UpdatePersonValue = (Person) => {
        Visitors.splice(userid, 1, Person);
        UpdateData(
            {
                ...Data.Datos,
                detalles: Visitors,
            }
        );
    }

    const UpdateTerminal = (Terminal) => {
        UpdateData(
            {
                ...Data.Datos,
                "terminal": Terminal,
            }
        );
    }
    const UpdateUserPayload = (user) => {
        UpdateData(
            {
                ...Data.Datos,
                "user_assigned": user,
            }
        );
    }

    const Updatespecificdata = (key, value) => {
        UpdateData(
            {
                ...Data.Datos,
                [key]: value,
            }
        );
    }
    const ResetData = () => {

        UpdateData(
            {
                ...Datos,
                "terminal": Data.Datos.terminal,
                "promociones_servicios":true
            }
        );
    }
    const isIOS = () => {
        return /iPad|iPhone|iPod/.test(navigator.userAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    }


    const values = {
        contact: contact,
        Data: Data,
        userid: userid,
        file: file,
        comprobante: comprobante,
        primaytheme: primaytheme,
        myConfig: configurations,
        lenguage: lenguage,
        LoggedUser: LoggedUser,
        token: token,
        card: card,
        paymentAmounts: paymentAmounts,
        ServiceChargeActive: ServiceChargeActive,
        TestPaymentMode: TestPaymentMode,
        limitvisitors: limitvisitors,
        Sandbox: Sandbox,
        voucherImage:voucherImage,
        setVoucherImage,
        setcard,
        setConfigs,
        setLenguage,
        setID,
        setFile,
        setComprobante,
        setLoggedUser,
        getconfigurations,
        getReceipt,
        getOCR,
        getUsers,
        getFilterData,
        getSumData,
        getIncidents,
        getTransactionUser,
        getTransactionDetails,
        getTerminals,
        getTransactionUserRecords,
        getFirebaseConfig,
        getPhysicalPaymentRecord,
        getPhysicalPaymentRecordCompleted,
        getRefund,
        getOpenPayReport,
        getPreTransactions,
        getSums,
        getClosingDays,
        SerchByVoucher,
        SerchByPassport,
        SerchPretransaction,
        //postPayment,
        postLogin,
        postChangePassword,
        postResetPassword,
        postUser,
        postCompleteTransaction,
        putConfigurations,
        putUpdatePaymentGateway,
        postKushkiToken,
        postForwardEmail,
        postUploadOpenPayExcel,
        postLotClosures,
        postPreTransaction,
        postCompletePreTransaction,
        postValidateUser,
        postClosingDate,
        getLotClousers,
        putUser,
        putNewPayload,
        putPhysicalRecords,
        putMoveToRefunds,
        postchargeop3ds,
        updateTraveldataobj,
        UpdateUsers,
        UpdateUserPayload,
        UpdatePersonValue,
        UpdateTerminal,
        UpdateVisitors,
        UpdateData,
        Updatespecificdata,
        ResetData,
        isIOS
    }


    return (
        <Context.Provider value={values}>{children}</Context.Provider>
    )
}