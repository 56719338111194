import { useEffect, useState ,useRef} from "react"
import { useContext } from "react";
import { Context } from "../../../../../../context/Context";
import { getCurrentDate, month, year } from "../../../../../../data/Datos";


 
const UseContentPretransactions = ({ filter = "today", specificfilter = null }) => {
    const Pretransactionsfilters = {
        Personas:"numberofrecords",
        Cantidad:"amount",
        "Cargo por servicio": "servicecharge",
        "Terminal":"terminal",
        "Asesor":"asesor"
    };

    const datacontext = useContext(Context);
    const registPerPage = 10;
     const hasInitialized = useRef(false);
     const hasRealyInitialized = useRef(false);
     const hasPageChanged = useRef(false);

      const [filteredPagination, setFilteredPagination] = useState(null);

     const [type, setType] = useState(1);
     const alltransactionsactive = () => {
        if (filter === "today") return true;
        else return false;
      };

    const [currentPage, setCurrentPage] = useState(1)
    const Pagination = (newPage) => {
        if(currentPage===newPage)return;
        else {
            hasPageChanged.current = true;
            setCurrentPage(newPage);
        }
    }
    const [PreTransactionsList, setPreTransactionsList] = useState({
        headers: [ "Personas ", "Cantidad", "Cargo por servicio","Terminal","Asesor", "Fecha de creación","Completed",],
        data: [],
        totalSum: 0,
        omitkeys: ["id", "updatedAt", "payment", "dateSatQ", "hourSatQ", "useridSatQ", "idSatQ", "payload","comments","datecreateMex","imageUrl"]
    });




    const ResetData = ()=>{
        setPreTransactionsList({ ...PreTransactionsList, data: [], totalSum: 0 })
    }

    const MakeUrl =()=>{
        let complementaryurl1 = ""
        if (filter.includes("today")) {
            complementaryurl1 = "?StartDate=" + getCurrentDate() + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59&PageNumber=" + currentPage + "&PageSize=" + registPerPage+"&TransactionType=" +
            type;
        }
        else if (filter.includes("month")) {
            complementaryurl1 = "?StartDate=" + year() + "/" + month() + "/01" + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59&PageNumber=" + currentPage + "&PageSize=" + registPerPage+"&TransactionType=" +type;
        }
        else if (filter.includes("year")) {
            complementaryurl1 = "?StartDate=" + year() + "/01/01" + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59&PageNumber=" + currentPage + "&PageSize=" + registPerPage+ "&TransactionType=" +type;
        }  
        else {    
            complementaryurl1 = specificfilter.url + "&PageNumber=" + currentPage + "&PageSize=" + registPerPage+"&TransactionType=" +type
        }
        return ({complementaryurl1})
    }

    const getPreTransactions = (complementaryurl)=>{

        const pretransactionsRecived = (response) => {
            const Responsedata = response?.data?.data;

            if (Responsedata) {
                setPreTransactionsList({ ...PreTransactionsList, data: Responsedata.allPreTransaction, totalSum: Responsedata.totalSum});

            }
           
        }
        const pretransactionsFailed = (response) => {
            setPreTransactionsList({ ...PreTransactionsList, data: [], totalSum: 0});
        }
        datacontext.getPreTransactions(pretransactionsRecived,pretransactionsFailed,complementaryurl,alltransactionsactive());
    }



    const makeRequest = () => {
        ResetData();
        const {complementaryurl1} =MakeUrl();
        getPreTransactions(complementaryurl1);
    }

  

    const SerchByPretransaction =(search)=>{        const OnDataRecived = (response) => {
            const Responsedata = response.data.data;
        
            setPreTransactionsList({
              ...PreTransactionsList,
              data: Responsedata.allPreTransaction,
              totalSum: Responsedata.totalSum,
            });
          };
          const OnDataFailed = () => {
            setPreTransactionsList({
              ...PreTransactionsList,
              data: [],
              totalSum: 0,
              numericvalue: 0,
            });
          };
          const Time = filter.includes("month") ? "M" : "Y";
          const { complementaryurl1 } = MakeUrl();
          const complement = complementaryurl1.replace("?", "&");
          const finalurl =
            "?filter=" +
            Pretransactionsfilters[search.filter] +
            "&value=" +
            search.searchTerm +
            "&monthyear=" +
            Time +
            complement;
          if (search.searchTerm)
            datacontext.SerchPretransaction(OnDataRecived, OnDataFailed, finalurl,alltransactionsactive());
          else {
            const { complementaryurl1 } = MakeUrl();
            getPreTransactions(complementaryurl1);
          }
    }

    useEffect(() => {

        if (hasInitialized.current) {

            makeRequest();
        }
    }, [filter, specificfilter,type])

    useEffect(() => {
        if (!hasInitialized.current&&!hasRealyInitialized.current) {
            setTimeout(()=>{
                hasInitialized.current = true;
            },500)
            hasRealyInitialized.current = true;
            makeRequest();
        }
    }, [])

    useEffect(() => {
        if (hasPageChanged.current) {
         const { complementaryurl1 } = MakeUrl();
         if(filteredPagination)SerchByPretransaction(filteredPagination);
         else getPreTransactions(complementaryurl1);
        } 
     }, [currentPage]);




    return  {
              alltransactionsactive,
              PreTransactionsList,
              Pagination ,
              makeRequest,
              SerchByPretransaction,
              setType,
              setFilteredPagination
            };

 
}
export default UseContentPretransactions;