import * as React from "react";
import MasterCard from "../../assets/Images/Mastercard-logo.png";
import Visa from "../../assets/Images/Visa_Logo.png";
import AmericanExpress from "../../assets/Images/American_Express_logo.png";
import OpenPayLogo from "../../assets/Images/logo-openpay.im1669747112914im.avif";
import line from "../../assets/Images/divider-numU.svg";
import { useNavigate } from "react-router-dom";
import Amoount from "../Universals/Amount";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import CircularProgress from "../Universals/CircularProgress";
import { useContext, useEffect, useState } from "react";
import { Context } from "../../context/Context";
import HeaderNologo from "../Universals/HeaderNoLogo";
import ComplementariForm from "./subcomponents/ComplementariForm";
import { ThemeProvider } from "@mui/material/styles";
import { Link } from "@mui/material";
import { useLocation } from "react-router-dom";
import KioskButtons from "./subcomponents/KioskButtons";
import { useTotalPayment } from "./hooks/useTotalPayment";
import PaymentTypeSelector from "./subcomponents/PaymentTypeSelector";
import UserSelector from "./subcomponents/UserSelector";
import TerminalSelector from "./subcomponents/TerminalSelector";
import AprovalCodeForm from "./subcomponents/AprovalCodeForm";
import DigitalCardForm from "./subcomponents/DigitalCardForm/DigitalCardForm";
import { isQuiosco } from "../../permissions/permissions";

function TotalPayment() {
  const datacontext = useContext(Context);
  const location = useLocation();
  const txt = datacontext.myConfig.txt[datacontext.lenguage];
  const queryParams = new URLSearchParams(location.search);
  const Errormessage = queryParams.get("message");
  const {
    updateTotalPaymentData,
    updateTotalCardData,
    setValidations,
    ManageApiAsk,
    DataError,
    paymentFail,
    waitingResponse,
    totalPaymentData,
    validation,
  } = useTotalPayment({ Errormessage });

 
  const printTotal = () => {
    try {
      return datacontext?.Data?.Datos?.importe;
    } catch (e) {
      console.log(e);
      return <></>;
    }
  };

  useEffect(()=>{
    if(isQuiosco(datacontext?.LoggedUser, datacontext?.Sandbox) ){
      updateTotalPaymentData("paymentType", "unselected");
    }
    },[])
  const handleCheckboxChange = () => {
    try {
      const promocionesServicios =
        datacontext?.Data?.Datos?.promociones_servicios;
      const newValue =
        promocionesServicios !== undefined ? !promocionesServicios : true;
      datacontext.Updatespecificdata("promociones_servicios", newValue);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      {waitingResponse && <CircularProgress />}
      <div className={waitingResponse ? "workzoneopacity" : "workzone"}>
        <HeaderNologo navigate="/ConfirmationUsers" />
        <h1>{txt[44]}</h1>

        <>
          <h5>${printTotal()} MXN</h5>
          <h3>{txt[45]}</h3>

          <div className="cardzone1">
            <div className="divlogos">
              <img className="visa" src={Visa} />
              <img className="mastercard" src={MasterCard} />
              <img className="mastercard" src={AmericanExpress} />
              <img className="openpay" src={OpenPayLogo} />
            </div>
          </div>

          <>
            {DataError.status ? (
              <Alert severity="warning">
                <AlertTitle>{txt[46]}</AlertTitle>
                {txt[47]}
              </Alert>
            ) : (
              <></>
            )}
            <ThemeProvider theme={datacontext.primaytheme}>
              <div id="PayForm">
                {isQuiosco(datacontext?.LoggedUser, datacontext?.Sandbox) ? (
                  <>
                    <KioskButtons
                      onChange={(paymenttype) => {
                     
                        updateTotalPaymentData("paymentType", paymenttype);
                      }}
                    />
                  </>
                ) : (
                  <>
                    <UserSelector
                      onChange={(user) => {
                        updateTotalPaymentData("userSelected", user);
                      }}
                    />
                    <TerminalSelector
                      onChange={(terminal) => {
                        updateTotalPaymentData("terminalSelected", terminal);
                      }}
                    />
                    <PaymentTypeSelector
                      onChange={(paymenttype) => {
                        console.log("Se esta implementando aqui");
                        updateTotalPaymentData("paymentType", paymenttype);
                      }}
                    />
                    <AprovalCodeForm
                      paymentType={totalPaymentData.paymentType}
                      onAprovalCodeChange={(aprobalcode) => {
                        updateTotalPaymentData("approvalCode", aprobalcode);
                      }}
                      onCommentChange={(coment) => {
                        {
                          updateTotalPaymentData("coment", coment);
                        }
                      }}
                      onPictureTaken={(picture) => {
                        updateTotalPaymentData("picture", picture);
                      }}
                    />
                  </>
                )}

                <DigitalCardForm
                  paymentType={totalPaymentData.paymentType}
                  onCardChange={(card) => {
                    updateTotalCardData(card);
                  }}
                  ValidData={(valid) => {
                    setValidations({ ...validation, card: valid });
                    console.log(valid);
                  }}
                />
                <ComplementariForm
                  paymentType={totalPaymentData.paymentType}
                  onCardDataChange={(card) => {
                    updateTotalCardData(card);
                  }}
                  ValidComplementary={(valid) => {
                    setValidations({ ...validation, complementary: valid });
                    console.log(valid);
                  }}
                />
              </div>

              <input
                type="checkbox"
                checked={
                  datacontext?.Data?.Datos?.promociones_servicios ?? true
                }
                className="terms"
                value="checkbox"
                onChange={handleCheckboxChange}
              />
              <Link
                className="terms"
                htmlFor="terms"
                onClick={handleCheckboxChange}
                style={{ textDecoration: "none" }}
              >
                {txt[68]
                  ? txt[68]
                  : "I agree to receive promotions and information"}
              </Link>
            </ThemeProvider>
          </>
        </>

        {paymentFail != null ? (
          <Alert severity={"error"}>
            <AlertTitle>{txt[33]}</AlertTitle>
            {paymentFail}
          </Alert>
        ) : (
          <></>
        )}
        <img className="divider" src={line} />

        <div id="pagos">
          <Amoount />
          {waitingResponse || totalPaymentData.paymentType === "unselected" ? (
            <></>
          ) : (
            <button className="stylebtn1" onClick={ManageApiAsk}>
              {txt[49]}
            </button>
          )}
        </div>
      </div>
    </>
  );
}

export default TotalPayment;
