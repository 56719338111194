import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField } from "@mui/material";

const ChangeUserPassword = ({ open, onClose, onAgree }) => {
  const [password, setPassword] = useState("");

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleAgreeClick = () => {
    onAgree(password); // Envía la contraseña al hacer clic en "Agree"
    setPassword(""); // Limpia el campo después de enviar la contraseña
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Cambiar contraseña"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Escribe la nueva contraseña:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="password"
            label="Password"
            type="password"
            fullWidth
            variant="outlined"
            value={password}
            onChange={handlePasswordChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>cancelar</Button>
          <Button onClick={handleAgreeClick} autoFocus>
            Cambiar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default ChangeUserPassword;
