import { useState, useContext } from "react";
import Table from "../Table/Table";
import PayloadViewer from "../PayloadModal/PayloadViewer";
import ActionModal from "../../../../../../Universals/ActionModal";
import useTransactions from "./hooks/useTransactions";
import { Grid, ToggleButton, ToggleButtonGroup } from "@mui/material";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import TapAndPlayIcon from "@mui/icons-material/TapAndPlay";
import PaymentIcon from "@mui/icons-material/Payment";
import { isSupport } from "../../../../../../../permissions/permissions";
import { Context } from "../../../../../../../context/Context";

const Transactions = ({ contentdashboard }) => {
  const datacontext = useContext(Context);
  const {
    TransactionsList,
    TransactionDetailsList,
    transactiondata,
    openPayloadViewer,
    emailSendStatus,
    keysEditablesTransactions,
    keysVisiblesTransactions,
    handleTransactionSelected,
    handleClose,
    onResenEmailClicked,
    handlePageChange,
    handlePageChange2,
    setEmailSendStatus,
    voucherupdatedstatus,
    setVoucherUpdateStatus,
  } = useTransactions({ contentdashboard });

  const [filterPayments, setFilterPayments] = useState("all");

  const handleFilterChange = (event, newFilter) => {
    if (newFilter !== null) setFilterPayments(newFilter);
    if (filterPayments === "all") contentdashboard.setType(1);
    if (filterPayments === "manual") contentdashboard.setType(2);
    if (filterPayments === "non-manual") contentdashboard.setType(3);
  };
  let filteredTransactions;
  if (contentdashboard.alltransactionsactive()) {
    filteredTransactions = TransactionsList.data.filter((transaction) => {
      if (filterPayments === "all") return true;
      if (filterPayments === "manual") return transaction.manual;
      if (filterPayments === "non-manual") return !transaction.manual;
    });
  } else {
    filteredTransactions = TransactionsList.data;
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ToggleButtonGroup
            value={filterPayments}
            exclusive
            onChange={handleFilterChange}
            aria-label="Filtro de pretransacciones"
          >
            <ToggleButton value="all" aria-label="Mostrar todas">
              <FormatListBulletedIcon />
            </ToggleButton>
            <ToggleButton value="manual" aria-label="Mostrar solo manuales">
              <PaymentIcon />
            </ToggleButton>
            <ToggleButton
              value="non-manual"
              aria-label="Mostrar solo no manuales"
            >
              <TapAndPlayIcon />
            </ToggleButton>
          </ToggleButtonGroup>

          <Table
            headers={TransactionsList.headers && TransactionsList.headers}
            content={filteredTransactions}
            totalElements={TransactionsList.totalSum}
            omitKeys={TransactionsList.omitkeys}
            onPageChange={(page) => {
              contentdashboard.setFilteredPagination(null);
              setTimeout(() => {
                handlePageChange(page);
              }, 500);
            }}
            onPageChangeFilterd={(values) => {
              contentdashboard.setFilteredPagination(values.filter);
              setTimeout(() => {
                handlePageChange(values.page);
              }, 500);
            }}
            title="Transacciones por voucher"
            standardPagination={contentdashboard.alltransactionsactive()}
            actionButtonTitle="Detalles"
            onActionButtonClick={handleTransactionSelected}
            filters={[
              "Voucher",
              "Cantidad",
              "Codigo de transacción",
              "Asesor",
              "Terminal",
              "Folio",
            ]}
            onSearch={(search) => contentdashboard.SerchByVoucher(search)}
          />
        </Grid>

        <Grid item xs={12}>
          <Table
            headers={
              TransactionDetailsList.headers && TransactionDetailsList.headers
            }
            content={TransactionDetailsList.data}
            totalElements={TransactionDetailsList.totalSum}
            omitKeys={TransactionDetailsList.omitkeys}
            onPageChange={(page) => {
              contentdashboard.setFilteredPagination(null);
              setTimeout(() => {
                handlePageChange2(page);
              }, 500);
            }}
            onPageChangeFilterd={(values) => {
              contentdashboard.setFilteredPagination(values.filter);
              setTimeout(() => {
                handlePageChange2(values.page);
              }, 500);
            }}
            title="Transacciones por pasaporte"
            standardPagination={contentdashboard.alltransactionsactive()}
            filters={[
              "Pasaporte",
              "Cargo por servicio",
              "Codigo QR",
              "Voucher"
            ]}
            onSearch={(search) => contentdashboard.SerchByPassport(search)}
          />
        </Grid>
      </Grid>

      {transactiondata.payload && (
        <PayloadViewer
          open={openPayloadViewer}
          onClose={handleClose}
          transactiondata={transactiondata}
          keysEditables={keysEditablesTransactions}
          keysVisibles={keysVisiblesTransactions}
          onFinalActionButtonClicked={onResenEmailClicked}
          finalActionButtonLabel="Reenviar correo"
          enableeditPaymentGategay={isSupport(
            datacontext?.LoggedUser,
            datacontext?.Sandbox
          )}
          onVoucherEdited={(status) => {
            setVoucherUpdateStatus(status);
          }}
        />
      )}
      <ActionModal
        open={emailSendStatus !== null}
        onClose={() => setEmailSendStatus(null)}
        text={
          emailSendStatus
            ? "El correo se envió exitosamente!"
            : "Ocurrió un error al enviar el correo"
        }
      />

      <ActionModal
        open={voucherupdatedstatus !== null}
        onClose={() => {
          if (voucherupdatedstatus) contentdashboard.makeRequest();
          setVoucherUpdateStatus(null);
        }}
        text={
          voucherupdatedstatus
            ? "Se actualizo el ticket correctamente!"
            : "Ocurrió un error al actualizar"
        }
      />
    </>
  );
};

export default Transactions;
