const contactmail = "contact.us@satq.qroo.gob.mx";
export const myConfigstructure = {
  umaValue: null,
  price: null,
  lenguages: [],
  flags: [],
  txt: [],
};

export const myConfig = {
  umaValue: null,
  price: null,
  lenguages: ["English"],
  flags: ["US"],
  txt: [
    {
      1: "Start",
      2: "Welcome to Visitax, an authorized platform by the State Government of Quintana Roo for the collection and supervision of the payment of the fee stipulated in Article 51-Octies of the Law of Rights of the State of Quintana Roo, known as Visitax",
      3: "At Visitax, we are dedicated to ensuring a seamless and efficient experience for all foreign visitors upon their arrival in the state",
      4: "For any inquires or clarification, our support team is available during the following hours",
      5: "Support Hours:",
      6: "Monday to Friday from 8:00 AM to 5:00 PM",
      7: "Feel free to reach out to us via email at:",
      8: "Email:" + contactmail,
      9: "Make your payment",
      10: "Recover your receipt",
      11: "D A T A",
      12: "Departure date:",
      13: "Number of visitors:",
      14: "E-mail",
      15: "Confirm your e-mail",
      16: "I accept terms and conditions",
      17: "In accordance with the Federal Law on Protection of Personal Data Held by Private Parties , Visitax establishes the following terms and conditions for the responsible handling of data provided by the user: Visitax, platform authorized by the government of the State of Quintana Roo.",
      18: "Next",
      19: "Users Number",
      20: "Add user",
      21: "User ",
      22: "Start registration by scanning passport",
      23: "Start manual registration",
      24: "Successful",
      25: "Identity Verification",
      26: "Have your passport ready to scan",
      27: "Enable camera permissions on your device",
      28: "Ensure that there is adequate lighting",
      29: "CAPTURE FRONT VIEW OF YOUR PASSPORT",
      30: "Hold your passport open at the photo page (showing your photo and personal details).",
      31: "Repeat capture",
      32: "Data Verification",
      33: "Error",
      34: "Please complete the form.",
      35: "Please enter your name",
      36: "Name:",
      37: "Please enter your surname",
      38: "Surname:",
      39: "Please enter a valid passport",
      40: "Passport Number:",
      41: "Identification completed",
      42: "Identify next user",
      43: "Users Confirmation",
      44: "Total Payment",
      45: "Choose your payment method:",
      46: "Warning",
      47: "Please enter valid data",
      48: "An error has occurred while processing your payment.",
      49: "Pay",
      50: "Card Number",
      51: "Expire",
      52: "Payment Confirmation",
      53: "Please check your e-mail to obtain your QR code or",
      54: "Click here",
      55: "Enter your passport",
      56: "You have a valid receipt",
      57: "We could not find a valid receipt",
      58: "Click here to get your receipt",
      59: "Total",
      60: "Login",
      61: "Logged as",
      62: "Privacity Notice",
      63: "Choose a nationality",
      64: "Taxes amount",
      65: "Service charge",
      66: "Choose your payment method",
      67: "Phone number",
      68: "I agree to receive promotions and information",
      69: "Pay with physical card",
      70: "Pay with digital card",
      71: "Complete transaction",
      72: "Enter approval code",
      73: "Take voucher picture",
      74: "Enter a comment",
      75: "Terms & conditions",
      76: "Error generating the token: Validate the data entered on the screen",
      77: "Failed to generate payment",
      78: "VISITAX Obligations for International Visitors in Quintana Roo",
      79: "According to Article 51-Octies of the Law of Rights of the State of Quintana Roo, foreign visitors must pay the VISITAX for the use of public domain real estate in the state. This obligation does not apply to those entering through the southern border. VISITAX is mandatory regardless of the departure mode, and failure to pay it is a serious violation that could limit the freedom of movement within the state.",
      80: "VISITAX agents are present at Cancun airport terminals (arrivals, departures, and secure areas). It is mandatory to stop if approached. Payment of VISITAX and obtaining a QR code as proof are indispensable requirements before leaving the state. Quintana Roo includes tourist destinations such as Cancun, Cozumel, Playa del Carmen, Riviera Maya, Holbox, Isla Mujeres, Puerto Aventuras, Puerto Morelos, Bacalar, and Tulum.",
      81: "You will receive your QR code immediately by email. If you do not find it, check your spam or junk mail folders. Verify the validity of the code by scanning it with a smartphone camera. If there are errors in travel dates or personal data, or if your plans change, you can request the reissuance of the QR code. Ensure the information is accurate to avoid issues.",
      82: "Frequently Asked Questions",
      83: "Below are some of the most frequently asked questions about VISITAX",
      84: "What is VISITAX?",
      85: "VISITAX is a FEE collected by the State of Quintana Roo. It is an available revenue that, under Article 25 of the Expenditure Budget for the 2024 fiscal year, must be allocated to the concepts mentioned in Article 14 of the Financial Discipline Law for Federative Entities and Municipalities. Please note that VISITAX is independent of the TUA (airport usage fee) applied to airline tickets, as well as the environmental fee charged in most hotels and all-inclusive resorts.",
      86: "Why does the state collect VISITAX?",
      87: "Cancun and the State of Quintana Roo are world-renowned destinations attracting millions of visitors annually. As such, they require significant investments to support their growth and ensure a positive experience for all visitors. This VISITAX is a fee that falls under the available revenue category, which, under Article 25 of the Expenditure Budget for the 2024 fiscal year, must be allocated to the concepts mentioned in Article 14 of the Financial Discipline Law for Federative Entities and Municipalities. Financial Discipline Law for Federative Entities and Municipalities (diputados.gob.mx)",
      88: "Should I be asked to pay VISITAX in cash?",
      89: "Absolutely not.",
      90: "What is Article 51-Octies?",
      91: "Foreign visitors entering the State of Quintana Roo must pay a fee for the use and enjoyment of public domain goods. Foreign visitors entering the state through the southern border are exempt from payment.",
      92: "Does VISITAX store your credit card information?",
      93: "No, VISITAX, powered by Travelkore, does not store this data, as it could still be vulnerable to a data breach. Even with security measures in place, storing credit card information online increases the risk of theft and fraudulent purchases. Manually entering credit card information during a purchase, as in this case, significantly reduces the likelihood of it being compromised.",
      94: "Should I pay more than $17.00 USD or $22.50 CAD, including service and convenience fees, per person?",
      95: "Your authorized travel agency may apply a service fee for paying your VISITAX on your behalf. Ensure you are aware of the cost of this additional service.",
      96: "Are there other commercial websites collecting VISITAX?",
      97: "VISITAX powered by Travelkore is an official VISITAX payment system contracted by the state-designated agency, Urquitem SA de CV, to collect VISITAX. Other websites claiming to be authorized may charge variable amounts up to $56 USD. You should avoid using these sites as they are NOT AUTHORIZED to collect VISITAX, and your QR code may be invalid or not received after payment.",
      98: "How will the charge appear on my credit card statement?",
      99: "Mexican Stay Tax",
      100: "How much does VISITAX cost?",
      101: "The VISITAX rate is set by law at 2.5 U.M.A., equivalent to 271 Mexican pesos. Travelkore has been instructed to collect this fee at a fixed rate of $17.00 USD, $22.50 CAD, €16.25 EUR, or £13.50 GBP. For consumers in the US, Canada, Europe, and the UK, Travelkore offers the option to pay in local currency, allowing you to avoid any fees, including foreign exchange charges (up to 3%) that your credit card company might apply.",
      102: "What is UMA?",
      103: "The Unit of Measure and Update (UMA) is the economic reference in pesos to determine the amount of payment for obligations and assumptions provided in federal and state laws, as well as the legal provisions arising from them.",
      104: "Is the service refundable?",
      105: "If the QR code is not delivered, all expenses will be refunded. No refunds are accepted under any circumstances once the QR code has been delivered.",
      106: "What credit cards are accepted?",
      107: "We accept Visa, Mastercard, and Amex.",
      108: "What is a government QR code?",
      109: "The QR code you will receive after processing your payment is issued by the state of Quintana Roo and is the only proof accepted for the tax payment.",
      110: "When will I receive my QR code?",
      111: "The QR code is generated immediately after processing your payment. You will receive an email with your QR code and can also log into your free Carekore account to view or download it.",
      112: "What happens if I accidentally delete my QR code?",
      113: "You can easily retrieve your QR code by accessing your email.",
      114: "Do all passengers, regardless of age (including infants and children), have to pay?",
      115: "There is no age exemption, all foreign visitors must pay the tax. The only people who are exempt from the Visitax are Mexican citizens, people with the appropriate permanent/temporary residency status (with permission to stay more than 180 days) and those entering through the southern border.",
      116: "When do I have to pay the tax?",
      117: "You must pay the tax BEFORE leaving the state. To avoid delays at the airport, we recommend paying in advance. With Travelkore, you can pay up to a year before your travel dates.",
      118: "What to expect when stopped by a government official in Quintana Roo?",
      119: "You will need to present your QR code when requested. The official will scan the smaller QR code and access an exclusive government dashboard displaying your VISITAX records. If you do not have a QR code, you must purchase one. After doing so, the official will scan the smaller QR code to verify previous payments. If a consumer scans the small barcode, they will receive an access denied message, which is normal.",
    },
  ],
};
