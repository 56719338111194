
import { Grid, Button } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { ExportExcel } from "./components/ExcelReport/PhysicalExcelReport";
import { useEffect } from "react";

const PhysicalReports = ({
    PayementsVouchersCompleted,
    PayementsVouchers
}) => {

  useEffect(()=>{ 
    console.log(PayementsVouchersCompleted);
    console.log(PayementsVouchers);

  },[PayementsVouchersCompleted,PayementsVouchers])

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} lg={4}>
          {PayementsVouchers.data && PayementsVouchersCompleted.data ? (
            <Button
              variant="outlined"
              startIcon={<DownloadIcon />}
              onClick={() => {
                let data1 = [];
                let data2 = [];
                if (PayementsVouchersCompleted.data.length > 0) {
                    PayementsVouchersCompleted.data.map((payment) => {
                        let newpayment= [payment.paymentGateway, payment.totalAmount, payment.description,payment.fullname,payment.dateCreate,payment.dateCreateMex,payment.imageUrl];
                        data1.push(newpayment)
                    });
        
                }
                if (PayementsVouchers.data.length > 0) {
                    PayementsVouchers.data.map((payment) => {
                        let newpayment= [payment.paymentGateway, payment.totalAmount, payment.description,payment.fullname,payment.dateCreate,payment.dateCreateMex,payment.imageUrl];
                        data2.push(newpayment)
                    });
        
                }
                const table1 ={
                    headers: PayementsVouchersCompleted.headers,
                    data: data1,
                    workbookname: "Fisicas con imagen"
                }
                const table2 ={
                    headers: PayementsVouchers.headers,
                    data: data2,
                    workbookname: "Fisicas sin imagen"
                }
                console.log(table1)
                console.log(table2)
                ExportExcel({tables:[table1,table2], name:"Reporte de transacciones fisicas"});
              }}
            >
              Exporta excel de transacciones fisicas
            </Button>
          ) : (
            <></>
          )}
        </Grid>
    
      </Grid>
    </>
  );
};
export default PhysicalReports;
