import { useEffect, useState,useRef } from "react"
import { useContext } from "react";
import { Context } from "../../../../../../context/Context";
import { getCurrentDate , month, year} from "../../../../../../data/Datos";


 
const useContentClousure = ({ filter = "today", specificfilter = null }) => {
    const datacontext = useContext(Context);
    const hasInitialized = useRef(false);
    const hasRealyInitialized = useRef(false);
    const [ClouserDay, SetClouserDay] = useState({
        headers: [ "Autorizador","Asesor","Vouchers Completos","Vouchers Subidos","Comentarios","Fecha de creación"],
        data: [],
        totalSum: 0,
        omitkeys: ["id","idSupervisor","idAdviser","dateCreateMex"]
    });
   
    const ResetData = ()=>{
        SetClouserDay({ ...ClouserDay, data: [], totalSum: 0 ,numericvalue:0})
    }
    const getClosingDays=(complementary)=>{
        const onClosingDays =(resp)=>{
            const Responsedata = resp.data.data;
            if (Responsedata) {
                SetClouserDay({ ...ClouserDay, data: Responsedata, totalSum: Responsedata.length });
            }
        }
        const onClosingFailed =(resp)=>{
            if(resp)SetClouserDay({ ...ClouserDay, data: [], totalSum: 0 ,numericvalue:0})
        }
        datacontext.getClosingDays(onClosingDays,onClosingFailed,complementary)
    }
 
   


    const MakeUrl =()=>{


        let complementaryurl = ""
 
 
        if (filter.includes("today")) {
            complementaryurl = "?StartDate=" + getCurrentDate() + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59"
   
        }
        else if (filter.includes("month")) {
            complementaryurl = "?StartDate=" + +year() + "/" + month() + "/01" + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59"
      
        }
        else if (filter.includes("year")) {
            complementaryurl = "?StartDate=" + year() + "/01/01" + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59"
  
        }  
        else {      
            complementaryurl = specificfilter.url
        }
 

        return ({complementaryurl})
    }
    const makeRequest = () => {
        ResetData();
        const {complementaryurl} =MakeUrl();
        getClosingDays(complementaryurl);
    }
    useEffect(() => {
        if (hasInitialized.current) {
          makeRequest();
        }
      }, [filter, specificfilter]);
    
      useEffect(() => {
        if (!hasInitialized.current && !hasRealyInitialized.current) {
          setTimeout(() => {
            hasInitialized.current = true;
          }, 500);
          hasRealyInitialized.current = true;
          makeRequest();
        }
      }, []);


    return {   
        ClouserDay,
        makeRequest };

 
}
export default useContentClousure;