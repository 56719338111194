import { IMaskInput } from 'react-imask';
import * as React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { useContext } from 'react';
import { Context } from '../../../../../context/Context';

const TextMaskCustom2 = React.forwardRef(function TextMaskCustom2(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="00/00"
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskCustom2.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function Dateexpire(props) {
  const datacontext = useContext(Context);
  const txt = datacontext.myConfig.txt[datacontext.lenguage];
  const [expire, setExpire] = React.useState({
    textmask: 'MM/YY',
    numberformat: '1320',
  });
  const [error, setError] = React.useState(false);
  const [helperText, setHelperText] = React.useState('');

  const handleExpire = (event) => {
    const value = event.target.value;
    if (value.length === 5) {
      const month = value.slice(0, 2);
      const year = '20' + value.slice(3, 5);
      const isValid = validateExpirationDate(month, year);
      props.setexpireValidation(isValid);
      setError(!isValid);
      setHelperText(isValid ? '' : '');
    }
    setExpire({
      ...expire,
      [event.target.name]: value,
    });

    props.onDateChange(value.slice(0, 2), value.slice(3, 5));
  };

  function validateExpirationDate(expirationMonth, expirationYear) {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // January is 0

    if (expirationYear > currentYear) {
      return true;
    } else if (expirationYear - currentYear === 0 && expirationMonth >= currentMonth) {
      return true;
    }

    return false;
  }

  return (
    <>
      <FormControl margin="normal" variant="standard" error={error}>
        <InputLabel>{txt[51]}</InputLabel>
        <Input
          placeholder={expire.textmask}
          onChange={handleExpire}
          onBlur={() => {
            if (!expire.textmask || !validateExpirationDate(expire.textmask.slice(0, 2), '20' + expire.textmask.slice(3, 5))) {
              setError(true);
              setHelperText('');
            } else {
              setError(false);
              setHelperText('');
            }
          }}
          name="textmask"
          id="outlined-basic"
          inputComponent={TextMaskCustom2}
          disabled={props.waitingResponse}
        />
        {error && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </>
  );
}

export default Dateexpire;
