import useAuthorization from "./hooks/useAuthorization";
import {
  Alert,
  Autocomplete,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  CircularProgress,
  Snackbar
} from "@mui/material";

const AuthorizationComponent = ({ open, onClose, onAutorizationDone }) => {
  const {
    usersList,
    password,
    loading,
    selectedUser,
    AuthError,
    handleUserChange,
    handleAuthorize,
    handlePasswordChange,
    setAuthError
  } = useAuthorization({ onAutorizationDone, onClose });
  const isAuthorizeButtonDisabled = !selectedUser || !password;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Autorizar Supervisor</DialogTitle>
      <DialogContent>
        {usersList && (
          <Autocomplete
            options={usersList}
            getOptionLabel={(option) => option.fullname}
            onChange={handleUserChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Seleccione Supervisor"
                variant="outlined"
              />
            )}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.fullname}
              </li>
            )}
          />
        )}
        <TextField
          label="Contraseña"
          type="password"
          fullWidth
          margin="normal"
          value={password}
          onChange={handlePasswordChange}
        />
        {/* Snackbar para mostrar el error */}
        <Snackbar
          open={AuthError}
          autoHideDuration={6000}
          onClose={() => setAuthError(false)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={() => setAuthError(false)}
            severity="error"
            sx={{ width: "100%" }}
          >
            Hubo un error con la autorización.
          </Alert>
        </Snackbar>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button
          onClick={handleAuthorize}
          color="primary"
          disabled={isAuthorizeButtonDisabled}
        >
          {loading ? <CircularProgress size={24} /> : "Autorizar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AuthorizationComponent;
