import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../../../context/Context";
import { usePretransaction } from "./usePretransaction";

export const useTotalPayment = ({Errormessage}) => {
  
  const datacontext = useContext(Context);
  const txt = datacontext.myConfig.txt[datacontext.lenguage];
  const [validation, setValidations] = useState({
    card: false,
    complementary: false,
  });
  const [DataError, setdataError] = useState({state:false,message:txt[47]});
  const [waitingResponse, setWaitingResponse] = useState(false);
  const [paymentFail, setPaymentFail] = useState(null);
  const navigate = useNavigate();
  const [totalPaymentData, setTotalPaymentData] = useState({
    paymentType: "digital",
    approvalCode: "",
    coment: "Se completa transaccion con numero de ticket:",
    picture: "",
    terminalSelected: datacontext?.Data?.Datos?.terminal,
    userSelected: datacontext?.LoggedUser?.id,
    card: {
      holder_name: "",
      card_number: "",
      email: "",
      phone_number: "",
      expiration_month: "",
      expiration_year: "",
      cvv2: "",
    },
  });

  const { configureApi, DigitalPayment ,WritenPayment,AndroidPayment} = usePretransaction({
    setPaymentFail,
    setWaitingResponse,
  });

  const updateTotalPaymentData = (key, value) => {
    const TotalPaymentCopy = { ...totalPaymentData, [key]: value };
    setTotalPaymentData(TotalPaymentCopy);
  };
  const updateTotalCardData = (value) => {

    const TotalPaymentCopy = {
      ...totalPaymentData,
      card: { ...totalPaymentData.card, value },
      card: {
        holder_name:value.holder_name?value.holder_name:totalPaymentData.card.holder_name,
        card_number: value.card_number?value.card_number:totalPaymentData.card.card_number,
        email:value.email?value.email:totalPaymentData.card.email,
        phone_number: value.phone_number?value.phone_number:totalPaymentData.card.phone_number,
        expiration_month: value.expiration_month?value.expiration_month:totalPaymentData.card.expiration_month,
        expiration_year: value.expiration_year?value.expiration_year:totalPaymentData.card.expiration_year,
        cvv2: value.cvv2?value.cvv2:totalPaymentData.card.cvv2,
      },
    };
    setTotalPaymentData(TotalPaymentCopy);
  };

  useEffect(() => {
    console.log(totalPaymentData);
  }, [totalPaymentData]);

  useEffect(() => {
    if (datacontext.myConfig.allconfigArray) {
      configureApi();
    }
  }, [datacontext.myConfig]);

  useEffect(() => {
    try {
      if (datacontext?.Data?.Datos?.detalles.length === 0) {
        navigate("/TravelData");
      }
    } catch (e) {
      console.log(e);
    }
  }, []);
  useEffect(() => {
    try {
      if (Errormessage) {
        setPaymentFail(Errormessage);
      }
    } catch (e) {
      console.log(e);
    }
  }, [Errormessage]);
  

  const PaymentValidation = (manual = false) => {
    if (manual && validation.complementary) {
      setdataError((prev)=>({...prev,status:false}));
      return true;
    }
    if (validation.complementary && validation.card) {
      setdataError((prev)=>({...prev,status:false}));
      return true;
    }
    setdataError((prev)=>({...prev,status:true}));
    return false;
  };

  const DigitalApiAsk = () => {
    if (PaymentValidation(false)) {
      setWaitingResponse(true);
      setdataError((prev)=>({...prev,status:false}));
      DigitalPayment(totalPaymentData);
    }
  };

  const WritenApiAsk = () => {
    datacontext.setVoucherImage(totalPaymentData.picture);
    if (
      totalPaymentData.approvalCode.length > 5 &&
      totalPaymentData.approvalCode.length < 26 &&
      PaymentValidation(true)
    ) {
      setWaitingResponse(true);
      setdataError((prev)=>({...prev,status:false}));
      WritenPayment(totalPaymentData);
    } else {
      setdataError((prev)=>({...prev,status:true}));
    }
  };

  const AndroidApiAsk = () => {
    if (PaymentValidation(true)) {
      setWaitingResponse(true);
      AndroidPayment();
    }
  };

  const ManageApiAsk = () => {
    if (
      totalPaymentData.paymentType === "physical" &&
      !totalPaymentData.picture
    ) {
      setdataError((prev) => ({ ...prev, status: true, message: "El voucher es obligatorio para completar la transacción." }));
      return;
    }
  
    datacontext.setVoucherImage(null);
  
    if (
      totalPaymentData.paymentType === "physical" ||
      totalPaymentData.paymentType === "completetransaction"
    ) {
      WritenApiAsk();
    } else if (totalPaymentData.paymentType === "physicalauto") {
      AndroidApiAsk();
    } else {
      DigitalApiAsk();
    }
  };

  return {
    totalPaymentData,
    DataError,
    paymentFail,
    waitingResponse,
    validation,
    updateTotalPaymentData,
    updateTotalCardData,
    setValidations,
    ManageApiAsk,
  };
};
