import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormHelperText from '@mui/material/FormHelperText';
import { useContext, useState } from 'react';
import { Context } from '../../../../../context/Context';

function CVV(props) {
    const datacontext = useContext(Context);
    const [showPassword, setShowPassword] = useState(false);
    const [cvv, setCVV] = useState("");
    const [error, setError] = useState(false);
    const [helperText, setHelperText] = useState("");

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handlerCVV = (event) => {
        const InputPattern = /^[0-9]{0,4}$/;
        const cvvInput = event.target.value;

        if (InputPattern.test(cvvInput)) {
            const isValid = cvvInput.length >= 3 && cvvInput.length <= 4;
            props.setCvvValidation(isValid);
            setError(!isValid);
            setHelperText(isValid ? "" : "");

            if (cvvInput.length <= 4) {
                setCVV(cvvInput);
                props.onCVVChange(cvvInput);
            }
        } else {
            setError(true);
            setHelperText("");
        }
    };

    return (
        <>
            <FormControl sx={{ m: 1, width: '50%' }} variant="outlined" error={error}>
                <InputLabel htmlFor="outlined-adornment-password">CVV</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                    label="CVV"
                    onChange={handlerCVV}
                    disabled={props.waitingResponse}
                    value={cvv}
                />
                {error && <FormHelperText>{helperText}</FormHelperText>}
            </FormControl>
        </>
    );
}

export default CVV;
