
import PaymentIcon from "@mui/icons-material/Payment"; // Alternativa para pago
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { IconButton } from "@mui/material";

const KioskButtons = ({ onChange = () => {} }) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "16px",
        }}
      >
        <IconButton
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          onClick={() => {
            onChange("physicalauto");
          }}
        >
          <PaymentIcon style={{ fontSize: "32px", color: "#001E2F" }} />
          <span style={{ fontSize: "12px" }}>Pay with physical card</span>
        </IconButton>
        <IconButton
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          onClick={() => {
            onChange("digital");
          }}
        >
          <AccountBalanceWalletIcon
            style={{ fontSize: "32px", color: "#001E2F" }}
          />
          <span style={{ fontSize: "12px" }}>Pay with digital card</span>
        </IconButton>
      </div>
    </>
  );
};
export default KioskButtons;
