import IncidentsComponent from "./components/Incidents/Incidents";
import Vouchers from "./components/Vouchers/Vouchers";
import Transactions from "./components/Transactions/Transactions";
import { Tabs, Tab, Box, IconButton } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh"; // Importar el ícono de Refresh
import { useState } from "react";
import Pretransactions from "./components/Pretransactions/Pretransactions";
import UseContentTransactions from "./hooks/useContentTransactions";
import UseContentIncidents from "./hooks/useContentIncidents";
import UseContentPretransactions from "./hooks/useContentPretransactions";
import UseContentPhysical from "./hooks/useContentPhysical";

const Analytics = ({ filter = "today", specificfilter = null }) => {
  const [value, setValue] = useState("transactions");
  const contentTransactions =UseContentTransactions({ filter, specificfilter });
  const contentIncidents =UseContentIncidents({ filter, specificfilter });
  const contentPreTransactions = UseContentPretransactions({filter, specificfilter})
  const contentPhysical = UseContentPhysical({filter, specificfilter})
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleRefresh =()=>{
    contentTransactions.makeRequest();
    contentIncidents.makeRequest();
    contentPreTransactions.makeRequest();
    contentPhysical.makeRequest();
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          sx={{
            maxWidth: "calc(100% - 50px)", // Deja espacio para el botón de refrescar en la derecha
          }}
        >
          <Tab value="transactions" label="Transacciones" />
          <Tab value="incidents" label="Incidencias" />
          <Tab value="phisicalregist" label="Registros físicos" />
          <Tab value="pretransactions" label="Pre-transacciones" />
        </Tabs>

        <IconButton color="primary" onClick={handleRefresh} aria-label="refresh">
          <RefreshIcon />
        </IconButton>
      </Box>

      <Box sx={{ marginTop: 2 }}>
        {value === "transactions" && (
          <Transactions
            contentdashboard={contentTransactions}
          />
        )}
        {value === "incidents" && (

          <IncidentsComponent
            contentdashboard={contentIncidents}
          />
        )}
        {value === "phisicalregist" && (
                <Vouchers
            contentdashboard={contentPhysical}
          />

        )}

        {value === "pretransactions" && (
                 <Pretransactions
            contentdashboard={contentPreTransactions}
          />

        )}
      </Box>
    </>
  );
};

export default Analytics;
