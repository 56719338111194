import { useEffect, useState,useRef } from "react";
import { useContext } from "react";
import { Context } from "../../../../../../context/Context";


const UseContentIncidents = ({
  filter = "today",
  specificfilter = null,
}) => {
  const datacontext = useContext(Context);
  const hasInitialized = useRef(false);
  const hasRealyInitialized = useRef(false);
  const [Incidents, setIncidents] = useState({
    headers: [
      "Voucher",
      "Personas",
      "Cantidad",
      "Codigo de transacción",
      "Fecha de creación",
      "Fecha de creación Mex",
    ],
    data: [],
    totalSum: 0,
    omitkeys: [
      "id",
      "payment",
      "detailCompleted",
      "users",
      "updatedAt",
      "payload",
    ],
  });
  const [Refunds, setRefunds] = useState({
    headers: [
      "Voucher",
      "Personas",
      "Cantidad",
      "Codigo de transacción",
      "Fecha de creación",
      "Fecha de creación Mex",
    ],
    data: [],
    totalSum: 0,
    omitkeys: [
      "id",
      "payment",
      "detailCompleted",
      "users",
      "updatedAt",
      "payload",
    ],
  });

  const ResetData = () => {
    setIncidents({
      ...Incidents,
      data: [],
      totalSum: 0,
      numericvalue: 0,
    });
    setRefunds({ ...Refunds, data: [], totalSum: 0 });
  };

  const getIncidents = () => {
    const OnDataIncidentsRecived = (response) => {
      const Responsedata = response.data.data;
      if (Responsedata) {
        setIncidents({
          ...Incidents,
          data: Responsedata,
          totalSum: Responsedata.length,
        });
      }
    };
    const OnDataIncidentsFailed = (response) => {
      setIncidents({ ...Incidents, data: [], totalSum: 0 });
    };

    datacontext.getIncidents(OnDataIncidentsRecived, OnDataIncidentsFailed);
  };

  const getRefunds = () => {
    const OnDataRefundsRecived = (response) => {
      const Responsedata = response.data.data;
      if (Responsedata) {
        setRefunds({
          ...Refunds,
          data: Responsedata,
          totalSum: Responsedata.length,
        });
      }
    };
    const OnDataRefundsFailed = (response) => {
      setRefunds({ ...Refunds, data: [], totalSum: 0 });
    };

    datacontext.getRefund(OnDataRefundsRecived, OnDataRefundsFailed);
  };

  const makeRequest = () => {
    ResetData();
    getIncidents();
    getRefunds();
  };

  useEffect(() => {
    if (hasInitialized.current) {
        makeRequest();
    }
}, [filter, specificfilter])

useEffect(() => {
    if (!hasInitialized.current&&!hasRealyInitialized.current) {
        setTimeout(()=>{
            hasInitialized.current = true;
        },500)
        hasRealyInitialized.current = true;
        makeRequest();
    }
}, [])


  return {
    Refunds,
    Incidents, 
    setIncidents,
    makeRequest,
  };
};
export default UseContentIncidents;
