import CancelIcon from "@mui/icons-material/Cancel";
import { ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { Context } from "../../context/Context";

function CardStartRegist(props) {
  const datacontext = useContext(Context);
  const txt = datacontext.myConfig.txt[datacontext.lenguage];
  const User = txt[21] + (props.id + 1);

  const navigate = useNavigate();

  return (
    <div key={props.id} className="cardzone">
      <div className="cardzone1">
        <p className="p2">{User}</p>
        <ThemeProvider theme={datacontext.primaytheme}>
          <CancelIcon
            onClick={() => datacontext.UpdateUsers(props.id)}
            color="primary"
            sx={{
              position: "relative",
              bottom: "20px",
              fontSize: "35px",
            }}
          />
        </ThemeProvider>
      </div>
      <p
        className="p3"
        onClick={() => {
          datacontext.setID(props.id);
          navigate("/IdentityVerifications");
        }}
      >
        {txt[22]}
      </p>
      <p
        className="p3"
        onClick={() => {
          datacontext.setID(props.id);
          navigate("/DataVerification");
        }}
      >
        {txt[23]}
      </p>
    </div>
  );
}
export default CardStartRegist;
