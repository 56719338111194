import * as React from "react";
import { styled, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Button } from "@mui/material";
import { Context } from "../../context/Context";
import { useContext, useEffect, useState } from "react";
import MainList from "./MainList";
import Configurations from "./Configurations/Configurations";
import Users from "./Users/Users";
import { useNavigate } from "react-router-dom";
import HouseIcon from "@mui/icons-material/House";
import { readDocumentById } from "../../context/puchdb/pouchdbData";
import { DeletUserData } from "../../context/puchdb/rutines";
import MainDashboard from "./PrincipalDashboard/MainDashboard";
import { AdministrationPermissions } from "../../permissions/permissions";
import SimpleBackdrop from "../Universals/BackDrop";
import DashboardAsesor from "./PrincipalDashboard/Components/DashboardAsesor/DashboardAsesor";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

// TODO remove, this demo shouldn't need to reset the theme.

function AdministrationPanel() {
  const datacontext = useContext(Context);
  const [open, setOpen] = React.useState(false);
  const [ActualView, setActualView] = React.useState("Tablero principal");
  const [waitingResponse, setWaitingResponse] = React.useState(false);

  const navigate = useNavigate();
  const toggleDrawer = () => {
    setOpen(!open);
  };

  // Function to handle user logout
  const Logout = () => {
    DeletUserData(datacontext);
    setTimeout(() => {
      window.location.href = "/Login";
    }, 3000);
    setWaitingResponse(true);
  };

  useEffect(() => {
    if (datacontext?.LoggedUser?.rol == "Soporte") {
      setActualView("Analiticos");
    }
    if (
      datacontext?.LoggedUser?.rol == "Asesor TF" ||
      datacontext?.LoggedUser?.rol == "Asesor"
    ) {
      setActualView("Asesor");
    }
  }, [datacontext.LoggedUser]);

  useEffect(() => {
    const checkUser = async () => {
      try {
        readDocumentById("logged_user")
          .then((doc) => {
            datacontext.setLoggedUser(doc);
            if (
              AdministrationPermissions(
                datacontext.LoggedUser,
                datacontext.Sandbox
              )
            )
              navigate("/Login");
          })
          .catch((e) => {
            console.error("Error fetching user from database:", e);
            navigate("/Login");
          });
      } catch (error) {
        console.error("Error fetching user from database:", error);
        navigate("/Login");
      }
    };
    if (datacontext.LoggedUser == null) {
      checkUser();
    }
  }, [datacontext, navigate]);

  const ChooseView = () => {
    if (!(datacontext.token === "")) {
      if (ActualView.includes("Tablero principal")) {
        return <MainDashboard selector={1} />;
      }
      if (ActualView.includes("Analiticos")) {
        return <MainDashboard selector={2} />;
      }
      if (ActualView.includes("Reportes")) {
        return <MainDashboard selector={3} />;
      }
      if (ActualView.includes("Asesor")) {
        return <MainDashboard selector={4} />;
      }
      if (ActualView.includes("Usuarios")) {
        return (
          <>
            <Users />
          </>
        );
      }
      if (ActualView.includes("Configuraciones")) {
        return (
          <>
            <Configurations />
          </>
        );
      }
      if (ActualView.includes("Cierres de dia")) {
        return <MainDashboard selector={5} />;
      }

      return <></>;
    }
  };

  return (
    <ThemeProvider theme={datacontext.primaytheme}>
      {waitingResponse ? <SimpleBackdrop complete={true} /> : <></>}
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open} sx={{ width: "100%" }}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              {ActualView}
            </Typography>
            <IconButton color="inherit">
              <HouseIcon
                onClick={() => {
                  navigate("/");
                }}
              />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              marginTop: "24px",
            }}
          >
            <IconButton onClick={toggleDrawer}>
              {open ? <ChevronLeftIcon sx={{ fontSize: "35px" }} /> : <></>}
            </IconButton>
          </Toolbar>
          <Divider />
          <MainList setActualView={setActualView} />
          <Divider />
          <Button onClick={Logout}>Log out</Button>
        </Drawer>
        {ChooseView()}
      </Box>
    </ThemeProvider>
  );
}
export default AdministrationPanel;
