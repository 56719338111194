import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Box
} from "@mui/material";

const EditVoucherDialog = ({ open, transactionData, onClose, onAccept,voucherimage }) => {
  const [correctValue, setCorrectValue] = useState("");

  const handleAccept = () => {
    onAccept({
      voucher: transactionData.voucher,
      erroneo: transactionData.paymentgateway,
      correcto: correctValue,
    });
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Editar Voucher</DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1" gutterBottom>
          Voucher actual: {transactionData.paymentgateway}
        </Typography>

        {voucherimage && (
          <Box display="flex" justifyContent="center">
            <img
              src={voucherimage}
              alt="Voucher"
              style={{ maxWidth: "100%" }}
            />
          </Box>
        )}
        <TextField
          fullWidth
          label="Valor corregido"
          value={correctValue}
          onChange={(e) => setCorrectValue(e.target.value)}
          variant="outlined"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancelar
        </Button>
        <Button
          onClick={handleAccept}
          color="primary"
          disabled={!correctValue.trim()} // Deshabilita el botón si el campo está vacío
        >
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditVoucherDialog;
