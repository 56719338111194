import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import { SandboxprofilesId,  ProductionprofilesId,
  ListOfRolesKeys, } from "../../../../../permissions/permissions";
import SimpleBackdrop from "../../../../Universals/BackDrop";
import { Context } from "../../../../../context/Context";
import { useContext } from "react";

export default function RegistUser({ open, setOpen, setData }) {
  const datacontext = useContext(Context);

  const handleClose = () => {
    setOpen(false);
  };
  const [role, setRole] = React.useState(ListOfRolesKeys[0]);
  const [inputValue, setInputValue] = React.useState("");
  const [savingUser, setSavingUser] = React.useState({
    state: false,
    newUser: {},
  });
  const onOKSave = (response) => {
    setSavingUser({ state: false, newUser: {} });
    handleClose();
    setData(null);
  };
  const onFailed = (response) => {
    setSavingUser({ state: false, newUser: {} });
  };
  React.useEffect(() => {
    if (savingUser.state === true) {
      console.log("Guardando Usuario");
      datacontext.postUser(onOKSave, onFailed, savingUser.newUser);
    }
  }, [savingUser]);

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const newUser = {
              userName: formJson.userName,
              name: formJson.name,
              lastName: formJson.lastName,
              fatherLastName: formJson.fatherLastName,
              motherLastName: formJson.motherLastName,
              idRole: datacontext.Sandbox
                ? SandboxprofilesId[inputValue]
                : ProductionprofilesId[inputValue],
              password: "Dr123qwe",
            };
            setSavingUser({ state: true, newUser: newUser });
          },
        }}
      >
        {savingUser.state === true ? (
          SimpleBackdrop
        ) : (
          <>
            <DialogTitle>New User</DialogTitle>
            <DialogContent>
              <DialogContentText>Please enter user data.</DialogContentText>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="name"
                    name="name"
                    label="Name"
                    type="text"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="lastName"
                    name="lastName"
                    label="Second Name"
                    type="text"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="fatherLastName"
                    name="fatherLastName"
                    label="Father's Lastname"
                    type="text"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="motherLastName"
                    name="motherLastName"
                    label="Mother's Lastname"
                    type="text"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="userName"
                    label="Email Address"
                    name="userName"
                    autoComplete="email"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Autocomplete
                    value={role}
                    onChange={(event, newValue) => {
                      setRole(newValue);
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                      setInputValue(newInputValue);
                    }}
                    id="controllable-states-demo"
                    options={ListOfRolesKeys}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Select a role" />
                    )}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Regist
              </Button>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </React.Fragment>
  );
}
