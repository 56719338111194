import { useEffect, useContext, useState } from "react";
import { Context } from "../../../context/Context";
import {
  TotalPaymentSelectorPermission,
  RegularPaymentPermission,
  CompleteTransactionPermission,
} from "../../../permissions/permissions";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { isQuiosco } from "../../../permissions/permissions";

const PaymentTypeSelector = ({ onChange = () => {} }) => {
  const datacontext = useContext(Context);
  const [paymentType, setPaymentType] = useState("digital");
  const [selectorPermision, setSelectorPermision] = useState(false);
  useEffect(() => {
    if (
      TotalPaymentSelectorPermission(
        datacontext.LoggedUser,
        datacontext.Sandbox
      )
    ) {
      setSelectorPermision(true);
    } 
    else {
      setSelectorPermision(false);
      setPaymentType("digital");
    }
  }, [datacontext.LoggedUser]);

  return (
    <>
      {selectorPermision && (
        <FormControl fullWidth>
          <InputLabel id="payment-type-label">
            Choose your payment method
          </InputLabel>

          <Select
            labelId="payment-type-label"
            id="payment-type"
            value={paymentType}
            onChange={(e) => {
              onChange(e.target.value);
              setPaymentType(e.target.value);
            }}
            label="Choose your payment method"
          >
            {RegularPaymentPermission(
              datacontext.LoggedUser,
              datacontext.Sandbox
            ) && <MenuItem value={"physical"}>Pay with physical card</MenuItem>}

            {RegularPaymentPermission(
              datacontext.LoggedUser,
              datacontext.Sandbox
            ) && <MenuItem value={"digital"}>Pay with digital card</MenuItem>}
            {RegularPaymentPermission(
              datacontext.LoggedUser,
              datacontext.Sandbox
            ) &&
              typeof Android !== "undefined" &&
              Android.receiveJson && (
                <MenuItem value={"physicalauto"}>
                  Automatic Pay with physical card
                </MenuItem>
              )}
            {CompleteTransactionPermission(
              datacontext.LoggedUser,
              datacontext.Sandbox
            ) && (
              <MenuItem value={"completetransaction"}>
                Complete Transaction
              </MenuItem>
            )}
          </Select>
        </FormControl>
      )}
    </>
  );
};
export default PaymentTypeSelector;
