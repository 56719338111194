import { useEffect, useState } from "react";
import { useContext } from "react";
import { Context } from "../../../../../../context/Context";
import { getCurrentDate, month, year } from "../../../../../../data/Datos";

const useAdvisorContent = ({ filter = "today", specificfilter = null }) => {
  const datacontext = useContext(Context);
  const [currentPage,setCurrentPage] = useState(1);
  const [value, setValue] = useState("transactions");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if(value.includes("phisicalregist")&&!authorization.openphisicalregist.state){
      setOpenAuthorization(true);
    }

  }, [value]);
  const registPerPage = 10;
  const keysEditablesTransactions = [];
  const keysVisiblesTransactions = [
    "personas",
    "importe",
    "service_charge",
    "manual",
    "correo",
    "telefono",
    "detalles",
  ];

  const [PayementsVouchers, setPaymentsVoucher] = useState({
    headers: [
      "Codigo de transacción",
      "Descripción",
      "Usuario",
      "Fecha Cancun",
      "Fecha Mex",
    ],
    data: [],
    totalSum: 0,
    omitkeys: ["id", "idTransaction", "lotClosing", "imageUrl", "idUser"],
  });
  const alltransactionsactive = true;

  const [PayementsVouchersCompleted, setPaymentsVoucherCompleted] = useState({
    headers: [
      "Codigo de transacción",
      "Descripción",
      "Usuario",
      "Fecha Cancun",
      "Fecha Mex",
    ],
    data: [],
    totalSum: 0,
    omitkeys: ["id", "idTransaction", "lotClosing", "imageUrl", "idUser"],
  });

  const [transactionsCards, setTransactionsCards] = useState({
    TransactionsUsersOBJ: {
      Title: "Transacciones",
      Value: "-",
      SecundaryValue: "Este es el total de transacciones",
      numericvalue: 0,
    },
    PaxOBJ: {
      Title: "Pax",
      Value: "-",
      SecundaryValue: "Este es el total de Pax registrados",
      numericvalue: 0,
    },
    ManualTransaction: {
      Title: "Manuales",
      Value: "-",
      SecundaryValue: "Este es el total transacciones manuales",
      numericvalue: 0,
    },
    DigitalTransaction: {
      Title: "Digitales",
      Value: "-",
      SecundaryValue: "Este es el total transacciones digitales",
      numericvalue: 0,
    },
  });

  const [TransactionsList, setTransactionsList] = useState({
    headers: [
      "Voucher",
      "Personas ",
      "Cantidad",
      "Cargo por servicio",
      "Codigo de transacción",
      "Asesor",
      "Terminal",
      "Fecha de partida",
      "Fecha de creación",
      "Fecha de creación Mex",
      "Manual",
    ],
    data: [],
    totalSum: 0,
    omitkeys: [
      "id",
      "updatedAt",
      "payment",
      "dateSatQ",
      "hourSatQ",
      "useridSatQ",
      "idSatQ",
      "payload",
      "comments",
    ],
  });
  const [transactiondata, seTransactiondatavoucher] = useState({
    payload: "",
    transactionid: "",
    voucher: "",
    paymentgateway: "",
  });

  const [OpenAuthorizathionModal, setOpenAuthorization]= useState(false);
  
  const [openPayloadViewer, setopenPayloadViewer] = useState(false);
  useEffect(() => {
    if (transactiondata.payload) setopenPayloadViewer(true);
  }, [transactiondata]);
  const [authorization,setAuthorization]= useState({openphisicalregist:{state:false,user:""}})
  
  const ResetData = () => {
    setTransactionsCards((prev) => ({
      TransactionsUsersOBJ: {
        ...prev.TransactionsUsersOBJ,
        Value: "-",
        numericvalue: 0,
      },
      PaxOBJ: {
        ...prev.PaxOBJ,
        Value: "-",
        numericvalue: 0,
      },
      ManualTransaction: {
        ...prev.ManualTransaction,
        Value: "-",
        numericvalue: 0,
      },
      DigitalTransaction: {
        ...prev.DigitalTransaction,
        Value: "-",
        numericvalue: 0,
      },
    }));
    setPaymentsVoucher({ ...PayementsVouchers, data: [], totalSum: 0 });
    setPaymentsVoucherCompleted({
      ...PayementsVouchersCompleted,
      data: [],
      totalSum: 0,
    });
  };

  const transactionsUsersRecived = (response) => {
    const totalSum = response.data.data.totalSum;
    const totalPerson = response.data.data.totalPerson;
    const alltransaction = response.data.data.alltransaction;

    let manual = 0;
    let digital = 0;
    alltransaction.forEach((transaction) => {
      if (transaction.manual) manual++;
      else digital++;
    });
    setTransactionsList({
      ...TransactionsList,
      data: response.data.data.alltransaction,
      totalSum: response.data.data.totalSum,
    });
    setTransactionsCards((prev) => ({
      ...prev,
      TransactionsUsersOBJ: {
        ...prev.TransactionsUsersOBJ,
        Value: totalSum,
        numericvalue: totalSum,
      },
      PaxOBJ: {
        ...prev.PaxOBJ,
        Value: totalPerson,
        numericvalue: totalPerson,
      },
      ManualTransaction: {
        ...prev.ManualTransaction,
        Value: manual,
        numericvalue: manual,
      },
      DigitalTransaction: {
        ...prev.DigitalTransaction,
        Value: digital,
        numericvalue: digital,
      },
    }));
  };
  const transactionsUsersFailed = (response) => {
    ResetData();
  };

  const handleTransactionSelected = (row) => {
    seTransactiondatavoucher({
      payload: JSON.parse(row.payload),
      transactionid: row.id,
      voucher: row.voucher,
      paymentgateway: row.openpayKey,
    });
  };

   
  const Pagination = (newPage) => {
    setCurrentPage(newPage);
}

  const handleClose = () => {
    seTransactiondatavoucher({
      payload: "",
      transactionid: "",
      voucher: "",
      paymentgateway: "",
    });
    setopenPayloadViewer(false);
  };

  ///PHYSICAL PAYMENT
  const onPhysicalRecordRecived = (response) => {
    const Responsedata = response.data.data;
    if (Responsedata) {
      setPaymentsVoucher({
        ...PayementsVouchers,
        data: Responsedata,
        totalSum: Responsedata.length,
      });
    }
  };
  const OnPhysicalRecordFailed = (response) => {
    setPaymentsVoucher({ ...PayementsVouchers, data: [], totalSum: 0 });
  };

  const onPhysicalRecordCompletedRecived = (response) => {
    const Responsedata = response.data.data;
    if (Responsedata) {
      setPaymentsVoucherCompleted({
        ...PayementsVouchersCompleted,
        data: Responsedata,
        totalSum: Responsedata.length,
      });
    }
  };
  const OnPhysicalRecordCompletedFailed = (response) => {
    setPaymentsVoucherCompleted({
      ...PayementsVouchersCompleted,
      data: [],
      totalSum: 0,
    });
  };

  const makeRequest = () => {
    ResetData();
    let complementaryurl1 = "";
    let complementaryurl4 = "";
    if (filter.includes("today")) {
      complementaryurl1 =
        "?StartDate=" +
        getCurrentDate() +
        " 00:00:00&EndDate=" +
        getCurrentDate() +
        " 23:59:59&PageNumber=" +
        currentPage +
        "&PageSize=" +
        registPerPage;

        complementaryurl4 = "?StartDate=" + getCurrentDate() + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59"
    } else if (filter.includes("month")) {
      complementaryurl1 =
        "?StartDate=" +
        year() +
        "/" +
        month() +
        "/01" +
        " 00:00:00&EndDate=" +
        getCurrentDate() +
        " 23:59:59&PageNumber=" +
        currentPage +
        "&PageSize=" +
        registPerPage;
        complementaryurl4 = "?StartDate=" + +year() + "/" + month() + "/01" + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59"

    } else if (filter.includes("year")) {
      complementaryurl1 =
        "?StartDate=" +
        year() +
        "/01/01" +
        " 00:00:00&EndDate=" +
        getCurrentDate() +
        " 23:59:59&PageNumber=" +
        currentPage +
        "&PageSize=" +
        registPerPage;
        complementaryurl4 = "?StartDate=" + year() + "/01/01" + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59"

    } else {
      complementaryurl1 =
        specificfilter.url +
        "&PageNumber=" +
        currentPage +
        "&PageSize=" +
        registPerPage;

        complementaryurl4 = specificfilter.url

    }
    datacontext.getTransactionUser(
      transactionsUsersRecived,
      transactionsUsersFailed,
      complementaryurl1 + "&id=" + datacontext.LoggedUser.id,
      false
    );
    datacontext.getPhysicalPaymentRecord(onPhysicalRecordRecived,OnPhysicalRecordFailed,"?idUser=" + datacontext.LoggedUser.id);
    datacontext.getPhysicalPaymentRecordCompleted(onPhysicalRecordCompletedRecived,OnPhysicalRecordCompletedFailed,complementaryurl4+"&idUser=" + datacontext.LoggedUser.id);
  };

  useEffect(() => {
    makeRequest();
  }, [filter, specificfilter, currentPage]);

  return {
    TransactionsList,
    transactiondata,
    openPayloadViewer,
    keysEditablesTransactions,
    keysVisiblesTransactions,
    transactionsCards,
    PayementsVouchersCompleted,
    PayementsVouchers,
    alltransactionsactive,
    handleTransactionSelected,
    handleClose,
    makeRequest,
    Pagination,
    setOpenAuthorization,
    OpenAuthorizathionModal,
    authorization,
    setAuthorization,
    handleChange,
    setValue,
    value, 
  };
};
export default useAdvisorContent;
