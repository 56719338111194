import Cel from "../../assets/Images/CEL.png";
import Passport from "../../assets/Images/Passport.png";
import Selfie from "../../assets/Images/SELFIE.png";
import ChooseFileManager from "./ChooseFileManager";
import { useContext, useEffect, useState } from "react";
import { Context } from "../../context/Context";
import { useNavigate } from "react-router-dom";
import HeaderNologo from "../Universals/HeaderNoLogo";
import CameraViewer from "./CameraViewer";
import { Button } from "@mui/material";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { NotificationContext } from "../../context/NotificationContext";

function IndentityVerification() {
  const datacontext = useContext(Context);
  const txt = datacontext.myConfig.txt[datacontext.lenguage];
  const { askForNotificationPermission } = useContext(NotificationContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (datacontext.Data.Datos.detalles.length === 0) navigate("/TravelData");
  });
  const [openCamera, setOpenCamera] = useState(false);
  const handleclick = () => {
    setOpenCamera(true);
  };
  const onImageTaken = (file) => {
    datacontext.setFile(file);
    navigate("/CaptureVerification");
  };

  return (
    <div className="workzone">
      <HeaderNologo navigate="/NumberofUsers" />

      <div className="pictureprocess">
        {openCamera ? <CameraViewer onImageTaken={onImageTaken} /> : 
          <>
            <h1>{txt[25]}</h1>
            <div className="pictureprocessCtn">
              <h3 className="pictureprocessTxt">{txt[26]}</h3>
              <img id="passport" src={Passport}></img>
              <h3 className="pictureprocessTxt">{txt[27]}</h3>
              <img id="camera" src={Cel}></img>
              <h3 className="pictureprocessTxt">{txt[28]}</h3>
              <img id="selfie" src={Selfie}></img>
            </div>
            {datacontext.isIOS() ? (
              <div
                style={{ textDecoration: "none" }}
                to="/CaptureVerification"
                className="stylebtn1"
                onClick={() => {
                  askForNotificationPermission();
                }}
              >
                <ChooseFileManager />
              </div>
            ) : (
              <div
                style={{ textDecoration: "none" }}
                to="/CaptureVerification"
                className="stylebtn1"
                onClick={handleclick}
              >
                <Button>
                  <CameraAltIcon color="action" />
                </Button>
              </div>
            )}
          </>
        }
      </div>
    </div>
  );
}
export default IndentityVerification;
