import { useState } from "react";
import { TextField, Button } from "@mui/material";
import CameraModal from "./CameraModal";
const AprovalCodeForm = ({
  paymentType = "physical",
  onAprovalCodeChange = () => {},
  onCommentChange = () => {},
  onPictureTaken = () => {},
}) => {
  const [approvalCode, setApprovalCode] = useState("");
  const [coment, setComment] = useState(
    "Se completa transaccion con numero de ticket: "
  );
  const [picture, setPicture] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);
  const handleImageTaken = (file) => {
    setPicture(file);
    onPictureTaken(file);
  };
  return (
    <>
      {(paymentType === "physical" ||
        paymentType === "completetransaction") && (
        <>
          <TextField
            fullWidth
            label="Enter approval code"
            value={approvalCode}
            onChange={(e) => {
              if (e.target.value.length < 26) {
                setApprovalCode(e.target.value);
                onAprovalCodeChange(e.target.value);
              }
            }}
             InputProps={{
              style: { fontSize: 35, fontWeight: 'bold' }, // Ajusta el tamaño y negritas del texto
            }}
            InputLabelProps={{
              style: { fontSize: 15, fontWeight: 'bold' }, // Ajusta el tamaño y negritas del label
            }}
       
          />
          {picture && (
            <img id="passportview" src={URL.createObjectURL(picture)} />
          )}
          <Button onClick={handleOpenModal}>Take Voucher Picture</Button>

          <CameraModal
            open={isModalOpen}
            onClose={handleCloseModal}
            onImageTaken={handleImageTaken}
          />
        </>
      )}

      {paymentType === "completetransaction" && (
        <>
          <TextField
            fullWidth
            multiline
            label="Enter a comment"
            value={coment}
            onChange={(e) => {
              setComment(e.target.value);
              onCommentChange(e.target.value);
            }}
            InputProps={{
              style: { fontSize: 15, fontWeight: 'bold' }, // Ajusta el tamaño y negritas del texto
            }}
            InputLabelProps={{
              style: { fontSize: 15, fontWeight: 'bold' }, // Ajusta el tamaño y negritas del label
            }}
       
          />
        </>
      )}
    </>
  );
};
export default AprovalCodeForm;
