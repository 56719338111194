import { IMaskInput } from 'react-imask';
import * as React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { useContext } from 'react';
import { Context } from '../../../../../context/Context';

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask="0000-0000-0000-0000"
            inputRef={ref}
            onAccept={(value) => onChange({ target: { name: props.name, value } })}
            overwrite
        />
    );
});

TextMaskCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

function CardNumber(props) {
    const datacontext = useContext(Context);
    const txt = datacontext.myConfig.txt[datacontext.lenguage];

    function detectCardType(cardNumber) {
        const patterns = {
            visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
            mastercard: /^5[1-5][0-9]{14}$/,
            amex: /^3[47][0-9]{13}$/,
        };

        for (const cardType in patterns) {
            if (patterns[cardType].test(cardNumber)) {
                return cardType;
            }
        }

        return "Unknown";
    }

    const [cardNumber, setCardNumber] = React.useState({
        textmask: '0000-0000-0000-0000',
        numberformat: '1320',
    });

    const [error, setError] = React.useState(false);
    const [helperText, setHelperText] = React.useState('');

    const handleCardNumber = (event) => {
        const value = event.target.value.replaceAll("-", '');
        props.setCardType(detectCardType(value));
        setCardNumber({
            ...cardNumber,
            [event.target.name]: event.target.value,
        });
        props.onCardChange(value);
    };

    const validateCardNumber = () => {
        const value = cardNumber.textmask.replaceAll("-", '');
        if (!value || value.length < 16 || /\s/.test(value)) {
            setError(true);
            setHelperText('');
        } else {
            setError(false);
            setHelperText('');
        }
    };

    return (
        <>
            <FormControl variant="standard" error={error}>
                <InputLabel>{txt[50]}</InputLabel>
                <Input
                    placeholder={cardNumber.textmask}
                    onChange={handleCardNumber}
                    onBlur={validateCardNumber}
                    name="textmask"
                    id="outlined-basic-1"
                    inputComponent={TextMaskCustom}
                    disabled={props.waitingResponse}
                />
                {error && <FormHelperText>{helperText}</FormHelperText>}
            </FormControl>
        </>
    );
}

export default CardNumber;
