import React, { useState, useContext } from "react";
import { TextField, Checkbox, FormControlLabel, Button, Snackbar, Alert } from "@mui/material";
import AuthorizationComponent from "../AuthorizationComponent/AuthorizationComponent";
import { Context } from "../../../../../../../context/Context";
import { DeletUserData } from "../../../../../../../context/puchdb/rutines";

const DayClousure = () => {
  const dataContext = useContext(Context);
  const [comments, setComments] = useState("");
  const [vouchersComplete, setVouchersComplete] = useState(false);
  const [vouchersUploaded, setVouchersUploaded] = useState(false);
  const [openAuth, setOpenAuth] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });

  const handleCommentsChange = (event) => setComments(event.target.value);
  const handleVouchersCompleteChange = (event) => setVouchersComplete(event.target.checked);
  const handleVouchersUploadedChange = (event) => setVouchersUploaded(event.target.checked);

  const showMessage = (message, severity = "success") => {
    setSnackbar({ open: true, message, severity });
  };

  const closeSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleDayClosure = () => setOpenAuth(true);

  const Logout = () => {
    DeletUserData(dataContext);

  };

  const handleAuthorize = (auth) => {
    if (auth.state) {
      const onOkClosing = () => {
        setOpenAuth(false);
        showMessage("Cierre de día completo", "success");
        Logout();
      };

      const onFailClosing = () => {
        setOpenAuth(false);
        showMessage("Hubo un problema al registrar el cierre del día, vuelva a intentarlo por favor", "error");
      };

      const dayClosureData = {
        idSupervisor: auth.user.id,
        idAdviser: dataContext.LoggedUser.id,
        fisicalVoucherComplete: vouchersComplete,
        cloudVoucherComplete: vouchersUploaded,
        feedback: comments,
      };
      dataContext.postClosingDate(onOkClosing, onFailClosing, dayClosureData);
    }
  };

  return (
    <div>
      <TextField
        label="Comentarios"
        multiline
        rows={4}
        variant="outlined"
        fullWidth
        value={comments}
        onChange={handleCommentsChange}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={vouchersComplete}
            onChange={handleVouchersCompleteChange}
          />
        }
        label="Se entregan vouchers completos"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={vouchersUploaded}
            onChange={handleVouchersUploadedChange}
          />
        }
        label="Se suben todos los vouchers"
      />
      <Button variant="contained" color="primary" onClick={handleDayClosure}>
        Cierre de Día
      </Button>

      <AuthorizationComponent
        onAutorizationDone={handleAuthorize}
        open={openAuth}
        onClose={() => setOpenAuth(false)}
      />

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={closeSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={closeSnackbar} severity={snackbar.severity} sx={{ width: "100%" }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default DayClousure;
