import React, { useContext, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import FormHelperText from "@mui/material/FormHelperText";
import { Context } from "../../../context/Context";

export default function ComplementariForm({
  paymentType = "",
  onCardDataChange = () => {},
  ValidComplementary = () => {},
}) {
  const datacontext = useContext(Context);
  const txt = datacontext.myConfig.txt[datacontext.lenguage];

  const regexName = /^[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]*$/;
  const regexPhone = /^\d{0,15}$/;
  const regexEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  const [cardData, setcardData] = useState({
    holder_name: "",
    phone_number: "",
    email: "",
  });

  const [errors, setErrors] = useState({
    holder_name: "",
    email: "",
  });

  const handleChange = (prop) => (event) => {
    const value = event.target.value;

    if (prop === "holder_name" && regexName.test(value)) {
      setcardData((prev) => ({ ...prev, holder_name: value }));
      setErrors((prev) => ({
        ...prev,
        holder_name: value.length > 1 ? "" : "El nombre debe tener al menos 2 caracteres.",
      }));
    } else if (prop === "email") {
      const trimmedValue = value.replace(/\s/g, "");
      datacontext.Updatespecificdata("correo", trimmedValue);
      setcardData((prev) => ({ ...prev, email: trimmedValue }));
      setErrors((prev) => ({
        ...prev,
        email: regexEmail.test(trimmedValue) ? "" : "Por favor, ingrese un correo válido.",
      }));
    } else if (prop === "phone_number" && regexPhone.test(value)) {
      datacontext.Updatespecificdata("telefono", value);
      setcardData((prev) => ({ ...prev, phone_number: value }));
    }
  };

  useEffect(() => {
    onCardDataChange(cardData);
    const nameValid = cardData.holder_name.length > 1;
    const emailValid = regexEmail.test(cardData.email);
    ValidComplementary(nameValid && emailValid);
  }, [cardData]);

  return (
    <>
      {paymentType !== "unselected" && (
        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            "& .MuiTextField-root": { m: 1, width: "95%" },
          }}
          noValidate
          autoComplete="off"
        >
          <div>
            <TextField
              id="holder-name"
              label={`${txt[36]}:`}
              variant="outlined"
              value={cardData.holder_name}
              onChange={handleChange("holder_name")}
              error={Boolean(errors.holder_name)}
              helperText={errors.holder_name}
            />
            <TextField
              id="email"
              label={`${txt[14]}:`}
              variant="outlined"
              value={cardData.email}
              onChange={handleChange("email")}
              error={Boolean(errors.email)}
              helperText={errors.email}
            />
            <TextField
              id="phone-number"
              label={txt[67]}
              variant="outlined"
              value={cardData.phone_number}
              onChange={handleChange("phone_number")}
            />
          </div>
        </Box>
      )}
    </>
  );
}
