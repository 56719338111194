
import { Grid } from "@mui/material";
import Table from "../Analytiscs/components/Table/Table";
import usecontentClousure from "./hooks/useContentClousure";
import { useEffect } from "react";
const DayClousureViews = ({ filter = "today", specificfilter = null })=>{
  const {
    ClouserDay,
  } = usecontentClousure({ filter, specificfilter });

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Table
            headers={ClouserDay.headers && ClouserDay.headers}
            content={ClouserDay.data}
            totalElements={ClouserDay.totalSum}
            omitKeys={ClouserDay.omitkeys}
            onPageChange={()=>{}}
            title="Cierres de dia"
            standardPagination={true}
          />
        </Grid>
      </Grid>

     
 
    </>
  );
}
export default DayClousureViews;