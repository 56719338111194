import * as XLSX from 'xlsx';

// Helper para formatear valores monetarios
const formatCurrency = (value) => `$${parseFloat(value).toFixed(2)}`;

export const exportByTransaction = (Data,Dates) => {
    // Crear un nuevo libro de trabajo

    console.log(Data)
    let wb = XLSX.utils.book_new();

    // Crear los datos iniciales con encabezados
    let data = [
        ["Consecutivo", "Voucher", "Id de pasarela", "Fecha Cancún","Fecha CDMX", "Numero de registros", "Valor de impuestos", "Cargo por Servicio", "Cantidad","Fecha de Salida","User ID SATQ","ID SATQ","Fecha SAQT","Hora SATQ","Vendedor","Terminal"]
    ];

    // Rellenar los datos
    if (Data) {
        Data.map((value, id) => {
            data.push([
                id + 1, // Consecutivo
                value.voucher,
                value.openpayKey,
                value.datecreate,
                value.datecreateMex,
                value.numberofRecords,
                formatCurrency(value.amount - value.serviceCharge), // Valor de impuestos
                formatCurrency(value.serviceCharge), // Cargo por Servicio
                formatCurrency(value.amount), // Cantidad
                value.departureDate,
                value.useridSatQ,
                value.idSatQ,
                value.dateSatQ,
                value.hourSatQ,
                value.userName,
                value.terminal
            ]);
        });
    }

    // Convertir los datos a una hoja de trabajo
    let ws = XLSX.utils.aoa_to_sheet(data);

    // Ajustar el ancho de las columnas automáticamente
    const columnWidths = data[0].map((_, i) => ({
        wch: Math.max(...data.map(row => (row[i] ? row[i].toString().length : 10))) + 2
    }));
    ws['!cols'] = columnWidths;

    // Añadir filtros a los encabezados
    ws['!autofilter'] = { ref: ws['!ref'] };

    // Añadir la hoja de trabajo al libro de trabajo
    XLSX.utils.book_append_sheet(wb, ws, "Reporte por transaccion");

    // Escribir el libro de trabajo en un archivo
    XLSX.writeFile(wb, "Reporte por transaccion"+Dates.StartDate+"" +Dates.EndDate+".xlsx");
};

export const exportByPasport = (Data,Dates) => {
    console.log(Data);
    let wb = XLSX.utils.book_new();

    let data = [
        ["Consecutivo", "Voucher", "HashId", "Pasaporte", "Fecha de creación", "Cantidad","Cargos por servicio"]
    ];

    if (Data) {
        Data.map((value, id) => {
            data.push([
                id + 1,
                value.voucher,
                value.hashId,
                value.passport,
                value.createdAt,
                formatCurrency(value.amount),
                formatCurrency(value.servicecharge)
            ]);
        });
    }

    let ws = XLSX.utils.aoa_to_sheet(data);

    // Ajustar el ancho de las columnas automáticamente
    const columnWidths = data[0].map((_, i) => ({
        wch: Math.max(...data.map(row => (row[i] ? row[i].toString().length : 10))) + 2
    }));
    ws['!cols'] = columnWidths;

    // Añadir filtros a los encabezados
    ws['!autofilter'] = { ref: ws['!ref'] };

    XLSX.utils.book_append_sheet(wb, ws, "Reporte por pasaporte");

    XLSX.writeFile(wb, "Reporte por pasaporte"+Dates.StartDate+"" +Dates.EndDate+".xlsx");
};
