import { GetUserDataPouch, DeletUserData } from "../../context/puchdb/rutines";
import {
  Button,
  Grid,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Context } from "../../context/Context";
import LogoutIcon from "@mui/icons-material/Logout";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import { AdministrationPermissions } from "../../permissions/permissions";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import CreditCardOffIcon from "@mui/icons-material/CreditCardOff";
import { TotalPaymentSelectorPermission } from "../../permissions/permissions";
import SimpleBackdrop from "../Universals/BackDrop";
import { isQuiosco } from "../../permissions/permissions";
const LoggedBar = () => {
  const dataContext = useContext(Context);
  const [open, setOpen] = useState(false); // State for popup
  const txt = dataContext?.myConfig?.txt[dataContext.lenguage];
  const [waitingResponse, setWaitingResponse] = useState(false);

  // Check if the user is logged in and fetch data from PouchDB if necessary
  useEffect(() => {
    if (dataContext.LoggedUser == null) {
      GetUserDataPouch(dataContext);
    }
    checkTokenExpiration();
  }, [dataContext.LoggedUser]);

  // Function to handle user logout
  const Logout = () => {
    DeletUserData(dataContext);
    setTimeout(() => {
      window.location.href = "/Login";
    }, 3000);
    setWaitingResponse(true);
  };

  // Function to check if the token has expired
  const checkTokenExpiration = () => {
    if (dataContext.LoggedUser && dataContext.LoggedUser.lifeToken && !isQuiosco(dataContext?.LoggedUser, dataContext?.Sandbox)) {
      const tokenExpiryDate = new Date(dataContext.LoggedUser.lifeToken);
      const currentDate = new Date();
      if (currentDate >= tokenExpiryDate) {
        setOpen(true); // Open the popup
        setTimeout(() => {
          Logout();
        }, 3000);
      }
    }
  };

  // Check token expiration every minute (60000 ms)
  useEffect(() => {
    const interval = setInterval(() => {
      checkTokenExpiration();
    }, 10000); // Check every minute

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [dataContext.LoggedUser, dataContext.lifeToken]);

  // Handle popup close
  const handleClose = () => {
    setOpen(false);
    Logout(); // Logout user when the popup closes
  };

  return (
    <>
      {dataContext.LoggedUser == null ? (
        <></>
      ) : (
        <Grid container justifyContent="space-between" alignItems="center">
          {isQuiosco(dataContext?.LoggedUser, dataContext?.Sandbox) ? (
            <>
              <Grid item xs={9} sx={{ display: "flex" }}>
                <p className="logged">
                  {dataContext?.LoggedUser?.name}{" "}
                  {dataContext?.LoggedUser?.fatherLastName}{" "}
                  {dataContext?.LoggedUser?.motherLastName}
                </p>
                <p className="logged">
                  Terminal {dataContext?.Data?.Datos?.terminal}
                </p>
              </Grid>
            </>
          ) : (
            <>
              {waitingResponse ? <SimpleBackdrop complete={true} /> : <></>}
              <Grid item xs={9} sx={{ display: "flex" }}>
                <p className="logged">
                  {txt[61]} {dataContext?.LoggedUser?.name}{" "}
                  {dataContext?.LoggedUser?.fatherLastName}{" "}
                  {dataContext?.LoggedUser?.motherLastName}
                </p>
                <p className="logged">
                  Terminal {dataContext?.Data?.Datos?.terminal}
                </p>
              </Grid>
              {dataContext.TestPaymentMode === 0 ? (
                <></>
              ) : (
                <Grid item xs={1}>
                  <p className="logged">Test Mode: ON</p>
                </Grid>
              )}
              {AdministrationPermissions(
                dataContext.LoggedUser,
                dataContext.Sandbox
              ) && (
                <Grid item xs={1}>
                  <IconButton variant="outlined" href="Dashboard">
                    <SpaceDashboardIcon />
                  </IconButton>
                </Grid>
              )}
              <Grid item xs={1}>
                {TotalPaymentSelectorPermission(
                  dataContext.LoggedUser,
                  dataContext.Sandbox
                ) ? (
                  <CreditScoreIcon />
                ) : (
                  <CreditCardOffIcon />
                )}
              </Grid>

              <Grid item xs={1}>
                <IconButton variant="outlined" onClick={Logout}>
                  <LogoutIcon />
                </IconButton>
              </Grid>
            </>
          )}
        </Grid>
      )}

      {/* Dialog for session expiration */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Session Expired</DialogTitle>
        <DialogContent>
          Your session has expired. Please log in again.
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LoggedBar;
