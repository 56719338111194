import useAdvisorContent from "./hooks/useAdvisorContent";
import InfoCard from "../ContentDashboard/Components/InfoCard/InfoCard";
import { Grid, Tabs, Tab, IconButton, Box } from "@mui/material";
import Table from "../Analytiscs/components/Table/Table";
import PayloadViewer from "../Analytiscs/components/PayloadModal/PayloadViewer";
import Vouchers from "../Analytiscs/components/Vouchers/Vouchers";
import DayClousure from "./Components/DayClousure/DayClousure";
import RefreshIcon from "@mui/icons-material/Refresh"; // Importar el ícono de Refresh
import AuthorizationComponent from "./Components/AuthorizationComponent/AuthorizationComponent";

const DashboardAsesor = ({ filter = "today", specificfilter = null }) => {
  const {
    TransactionsList,
    transactiondata,
    openPayloadViewer,
    keysVisiblesTransactions,
    PayementsVouchersCompleted,
    PayementsVouchers,
    handleTransactionSelected,
    handleClose,
    transactionsCards,
    alltransactionsactive,
    makeRequest,
    Pagination,
    setOpenAuthorization,
    OpenAuthorizathionModal,
    authorization,
    setAuthorization,
    handleChange,
    value,
    setValue,
  } = useAdvisorContent({
    filter,
    specificfilter,
  });

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          sx={{
            maxWidth: "calc(100% - 50px)", // Deja espacio para el botón de refrescar en la derecha
          }}
        >
          <Tab value="transactions" label="Transacciones" />
          <Tab value="phisicalregist" label="Registros físicos" />
          <Tab value="dayclousure" label="Cierre de día" />
        </Tabs>

        <IconButton color="primary" onClick={makeRequest} aria-label="refresh">
          <RefreshIcon />
        </IconButton>
      </Box>
      {value === "transactions" && (
        <Grid container spacing={2}>
          {Object.keys(transactionsCards).map((key) => (
            <Grid item xs={12} md={6} lg={6} key={key}>
              <InfoCard element={transactionsCards[key]} />
            </Grid>
          ))}
          <Grid item xs={12}>
            <Table
              headers={TransactionsList.headers && TransactionsList.headers}
              content={TransactionsList.data}
              totalElements={TransactionsList.totalSum}
              omitKeys={TransactionsList.omitkeys}
              onPageChange={() => {}}
              title="Transacciones por voucher"
              standardPagination={true}
              actionButtonTitle="Detalles"
              onActionButtonClick={handleTransactionSelected}
            />
          </Grid>
        </Grid>
      )}
      {value === "phisicalregist" && (
        <Vouchers
          contentdashboard={{
            alltransactionsactive,
            PayementsVouchers,
            PayementsVouchersCompleted,
            Pagination,
            makeRequest,
          }}
        />
      )}
      {value === "dayclousure" && (
        <DayClousure/>
      )}
      <AuthorizationComponent
        onAutorizationDone={(auth) => {
          if (auth.state && !authorization.openphisicalregist.state) {
            setAuthorization((prev) => ({
              ...prev,
              openphisicalregist: auth,
            }));
            setOpenAuthorization(false);
          }
        }}
        open={OpenAuthorizathionModal}
        onClose={() => {
          if (!authorization.openphisicalregist.state) {
            setOpenAuthorization(false);
            setValue("transactions");
          }
        }}
      />
      {transactiondata.payload && (
        <PayloadViewer
          open={openPayloadViewer}
          onClose={handleClose}
          transactiondata={transactiondata}
          keysVisibles={keysVisiblesTransactions}
          finalActionButtonLabel="Cerrar"
        />
      )}
    </>
  );
};

export default DashboardAsesor;
