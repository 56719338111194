import { useEffect, useState, useRef } from "react";
import { useContext } from "react";
import { Context } from "../../../../../../context/Context";
import { getCurrentDate, month, year } from "../../../../../../data/Datos";

const UseContentTransactions = ({
  filter = "today",
  specificfilter = null,
}) => {
  const Vouchersfilters = {
    Voucher: "Voucher",
    Cantidad: "Amount",
    "Codigo de transacción": "PaymentGateway",
    Asesor: "Asesor",
    Terminal: "Terminal",
    Folio: "Folio",
  };
  const Pasportfilters = {
    Pasaporte: "Passport",
    "Cargo por servicio": "servicecharge",
    "Codigo QR": "hashid",
    Voucher: "Voucher",
    "Fecha de Partida": "departuredate",
  };

  const datacontext = useContext(Context);
  const registPerPage = 10;
  const hasInitialized = useRef(false);
  const hasRealyInitialized = useRef(false);
  const hasPageChanged = useRef(false);
  const hasPageChanged2 = useRef(false);
  const [type, setType] = useState(1);
  const [filteredPagination, setFilteredPagination] = useState(null);
  const alltransactionsactive = () => {
    if (filter === "today") return true;
    else return false;
  };
  const [currentPage, setCurrentPage] = useState(1);

  const Pagination = (newPage) => {
    if (currentPage === newPage) return;
    else {
      hasPageChanged.current = true;
      setCurrentPage(newPage);
    }
  };

  const [currentPage2, setCurrentPage2] = useState(1);
  const Pagination2 = (newPage) => {
    if (currentPage2 === newPage) return;
    else {
      hasPageChanged2.current = true;
      setCurrentPage2(newPage);
    }
  };

  const [TransactionsList, setTransactionsList] = useState({
    headers: [
      "Voucher",
      "Personas ",
      "Cantidad",
      "Cargo por servicio",
      "Codigo de transacción",
      "Asesor",
      "Terminal",
      "Fecha de partida",
      "Fecha de creación",
      "Manual",
      "Folio",
    ],
    data: [],
    totalSum: 0,
    omitkeys: [
      "id",
      "updatedAt",
      "payment",
      "dateSatQ",
      "hourSatQ",
      "useridSatQ",
      "idSatQ",
      "payload",
      "comments",
      "datecreateMex",
      "imageUrl",
    ],
  });

  const [TransactionDetailsList, setTransactionDetailList] = useState({
    headers: [
      "Pasaporte",
      "Cargo por servicio",
      "Cantidad",
      "Codigo QR",
      "Voucher",
      "Fecha de Partida",
      "Fecha de creación",
      "Fecha de creación Mex",
    ],
    data: [],
    totalSum: 0,
    omitkeys: ["id"],
  });

  const ResetData = () => {
    setTransactionsList({
      ...TransactionsList,
      data: [],
      totalSum: 0,
      numericvalue: 0,
    });
    setTransactionDetailList({
      ...TransactionDetailsList,
      data: [],
      totalSum: 0,
    });
  };

  const MakeUrl = () => {
    let complementaryurl1 = "";
    let complementaryurl2 = "";
    if (filter.includes("today")) {
      complementaryurl1 =
        "?StartDate=" +
        getCurrentDate() +
        " 00:00:00&EndDate=" +
        getCurrentDate() +
        " 23:59:59&PageNumber=" +
        currentPage +
        "&PageSize=" +
        registPerPage +
        "&TransactionType=" +
        type;
      complementaryurl2 =
        "?StartDate=" +
        getCurrentDate() +
        " 00:00:00&EndDate=" +
        getCurrentDate() +
        " 23:59:59&PageNumber=" +
        currentPage2 +
        "&PageSize=" +
        registPerPage +
        "&TransactionType=" +
        type;
    } else if (filter.includes("month")) {
      complementaryurl1 =
        "?StartDate=" +
        year() +
        "/" +
        month() +
        "/01" +
        " 00:00:00&EndDate=" +
        getCurrentDate() +
        " 23:59:59&PageNumber=" +
        currentPage +
        "&PageSize=" +
        registPerPage +
        "&TransactionType=" +
        type;
      complementaryurl2 =
        "?StartDate=" +
        year() +
        "/" +
        month() +
        "/01" +
        " 00:00:00&EndDate=" +
        getCurrentDate() +
        " 23:59:59&PageNumber=" +
        currentPage2 +
        "&PageSize=" +
        registPerPage +
        "&TransactionType=" +
        type;
    } else if (filter.includes("year")) {
      complementaryurl1 =
        "?StartDate=" +
        year() +
        "/01/01" +
        " 00:00:00&EndDate=" +
        getCurrentDate() +
        " 23:59:59&PageNumber=" +
        currentPage +
        "&PageSize=" +
        registPerPage +
        "&TransactionType=" +
        type;
      complementaryurl2 =
        "?StartDate=" +
        year() +
        "/01/01" +
        " 00:00:00&EndDate=" +
        getCurrentDate() +
        " 23:59:59&PageNumber=" +
        currentPage2 +
        "&PageSize=" +
        registPerPage +
        "&TransactionType=" +
        type;
    } else {
      complementaryurl1 =
        specificfilter.url +
        "&PageNumber=" +
        currentPage +
        "&PageSize=" +
        registPerPage +
        "&TransactionType=" +
        type;
      complementaryurl2 =
        specificfilter.url +
        "&PageNumber=" +
        currentPage2 +
        "&PageSize=" +
        registPerPage +
        "&TransactionType=" +
        type;
    }
    return { complementaryurl1, complementaryurl2 };
  };

  const SerchByPassport = (search) => {
    const OnDataTransactionDetails = (response) => {
      const Responsedata = response.data.data;
      setTransactionDetailList({
        ...TransactionDetailsList,
        data: Responsedata.allTransactionDetail,
        totalSum: Responsedata.totalSum,
      });
    };
    const OnDataTransactionFailed = () => {
      setTransactionDetailList({
        ...TransactionDetailsList,
        data: [],
        totalSum: 0,
      });
    };
    const Time = filter.includes("month") ? "M" : "Y";
    const { complementaryurl2 } = MakeUrl();
    const complement = complementaryurl2.replace("?", "&");
    const finalurl =
      "?filter=" +
      Pasportfilters[search.filter] +
      "&value=" +
      search.searchTerm +
      "&monthyear=" +
      Time +
      complement;

    if (search.searchTerm)
      datacontext.SerchByPassport(
        OnDataTransactionDetails,
        OnDataTransactionFailed,
        finalurl
      );
    else {
      const { complementaryurl2 } = MakeUrl();
      getTransactionDetail(complementaryurl2);
    }
  };

  const SerchByVoucher = (search) => {
    const OnDataRecived = (response) => {
      const Responsedata = response.data.data;
      setTransactionsList({
        ...TransactionsList,
        data: Responsedata.allPaymentRegister,
        totalSum: Responsedata.totalSum,
      });
    };
    const OnDataFailed = () => {
      setTransactionsList({
        ...TransactionsList,
        data: [],
        totalSum: 0,
        numericvalue: 0,
      });
    };
    const Time = filter.includes("month") ? "M" : "Y";
    const { complementaryurl1 } = MakeUrl();
    const complement = complementaryurl1.replace("?", "&");
    const finalurl =
      "?filter=" +
      Vouchersfilters[search.filter] +
      "&value=" +
      search.searchTerm +
      "&monthyear=" +
      Time +
      complement;
    if (search.searchTerm)
      datacontext.SerchByVoucher(OnDataRecived, OnDataFailed, finalurl);
    else {
      const { complementaryurl1 } = MakeUrl();
      getTransactions(complementaryurl1);
    }
  };

  const getTransactions = (complementaryurl) => {
    const OnDataRecived = (response) => {
      const Responsedata = response.data.data;
      setTransactionsList({
        ...TransactionsList,
        data: Responsedata.allPaymentRegister,
        totalSum: Responsedata.totalSum,
      });
    };
    const OnDataFailed = () => {
      setTransactionsList({
        ...TransactionsList,
        data: [],
        totalSum: 0,
        numericvalue: 0,
      });
    };
    datacontext.getFilterData(
      OnDataRecived,
      OnDataFailed,
      complementaryurl,
      alltransactionsactive()
    );
  };

  const getTransactionDetail = (complementaryurl) => {
    const OnDataTransactionDetails = (response) => {
      const Responsedata = response.data.data;
      const data = Responsedata?.responseTransactionDetails
        ? Responsedata?.responseTransactionDetails
        : Responsedata;
      const totalSum = Responsedata?.totalRecords
        ? Responsedata?.totalRecords
        : Responsedata.length;
      setTransactionDetailList({
        ...TransactionDetailsList,
        data: data,
        totalSum: totalSum,
      });
    };
    const OnDataTransactionFailed = () => {
      setTransactionDetailList({
        ...TransactionDetailsList,
        data: [],
        totalSum: 0,
      });
    };
    datacontext.getTransactionDetails(
      OnDataTransactionDetails,
      OnDataTransactionFailed,
      complementaryurl,
      alltransactionsactive()
    );
  };

  const makeRequest = () => {
    ResetData();
    const { complementaryurl1, complementaryurl2 } = MakeUrl();
    getTransactions(complementaryurl1);
    getTransactionDetail(complementaryurl2);
  };
  useEffect(() => {
    if (hasInitialized.current) {
      makeRequest();
    }
  }, [filter, specificfilter,type]);

  useEffect(() => {
    if (!hasInitialized.current && !hasRealyInitialized.current) {
      setTimeout(() => {
        hasInitialized.current = true;
      }, 500);
      hasRealyInitialized.current = true;
      makeRequest();
    }
  }, []);

  useEffect(() => {
    if (hasPageChanged.current) {
      const { complementaryurl1 } = MakeUrl();
      if (filteredPagination) SerchByVoucher(filteredPagination);
      else getTransactions(complementaryurl1);
    }
  }, [currentPage]);

  useEffect(() => {
    if (hasPageChanged2.current) {
      const { complementaryurl2 } = MakeUrl();
      if (filteredPagination) SerchByPassport(filteredPagination);
      else getTransactionDetail(complementaryurl2);
    }
  }, [currentPage2]);

  return {
    TransactionsList,
    alltransactionsactive,
    TransactionDetailsList,
    Pagination,
    Pagination2,
    makeRequest,
    SerchByPassport,
    SerchByVoucher,
    setType,
    setFilteredPagination,
  };
};
export default UseContentTransactions;
