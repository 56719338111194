import * as XLSX from 'xlsx';




export const ExportExcel = ({ tables, name }) => {
    console.log(tables);

    // Crear un nuevo libro de trabajo
    let wb = XLSX.utils.book_new();

    tables.map((table, id) => {
        let exceldata = [["Consecutivo", ...table.headers]];
        let rowHeights = []; // Para almacenar las alturas de las filas
        let columnWidths = []; // Inicializar anchos de columna dinámicos

        if (table.data) {
            table.data.map((value, index) => {
                let fila = [
                    (index + 1).toString(),
                    ...value.map((item, colIndex) => {
                        if (typeof item === 'string' && item.startsWith('https://')) {
                            // Insertar fórmula para mostrar imagen desde URL
                            rowHeights[index + 1] = { hpx: 250 }; // Ajustar altura de la fila (120px aprox)
                            columnWidths[colIndex + 1] = 50; // Asignar un ancho fijo mayor para columnas con imágenes
                            return { f: `IMAGEN("${item}")` };
                        } else {
                            // Calcular dinámicamente el ancho de otras columnas
                            let contentLength = item?.toString().length || 10;
                            columnWidths[colIndex + 1] = Math.max(
                                columnWidths[colIndex + 1] || 10,
                                contentLength + 2
                            );
                            return item;
                        }
                    })
                ];
                exceldata.push(fila);
            });
        }

        // Crear la hoja de trabajo
        let ws = XLSX.utils.aoa_to_sheet(exceldata);

        // Ajustar anchos de columna dinámicamente
        ws['!cols'] = columnWidths.map(width => ({ wch: width }));

        // Ajustar alturas de fila
        ws['!rows'] = rowHeights;

        // Añadir filtros a los encabezados
        if (ws['!ref']) {
            ws['!autofilter'] = { ref: ws['!ref'] };
        }

        // Añadir la hoja de trabajo al libro
        XLSX.utils.book_append_sheet(wb, ws, table.workbookname ? table.workbookname : "WORKBOOK" + id);
    });

    // Escribir el libro de trabajo en un archivo
    XLSX.writeFile(wb, name + ".xlsx");
};
