import { useEffect, useState,useRef } from "react"
import { useContext } from "react";
import { Context } from "../../../../../../context/Context";
import { getCurrentDate, month, year } from "../../../../../../data/Datos";


 
const UseContentPhysical = ({ filter = "today", specificfilter = null }) => {
   
    const datacontext = useContext(Context);
    const hasInitialized = useRef(false);
    const hasRealyInitialized = useRef(false);
    const hasPageChanged = useRef(false);
    const registPerPage = 10;
    const alltransactionsactive = ()=>{
        if(filter === "today") return true;
        else return false;
    }


    const [currentPage, setCurrentPage] = useState(1)
    const Pagination = (newPage) => {
        if(currentPage===newPage)return;
        else {
            hasPageChanged.current = true;
            setCurrentPage(newPage);
        }
    }

    const [PayementsVouchers, setPaymentsVoucher] = useState({
        headers: ["Codigo de transacción","Cantidad", "Descripción","Usuario","Fecha Cancun","Fecha Mex"],
        data: [],
        totalSum: 0,
        omitkeys: ["id","idTransaction","lotClosing","imageUrl","idUser"]
    });
 
    const [PayementsVouchersCompleted, setPaymentsVoucherCompleted] = useState({
        headers: ["Codigo de transacción", "Cantidad","Descripción","Usuario","Fecha Cancun","Fecha Mex"],
        data: [],
        totalSum: 0,
        omitkeys: ["id","idTransaction","lotClosing","imageUrl","idUser"]
    });

    const [LotClouser, setLotClouser] = useState({
        headers: [ "Descripción","Usuario", "Fecha de creación"],
        data: [],
        totalSum: 0,
        omitkeys: ["id","lotClosing","imageUrl","idUser"]
    });




    const ResetData = ()=>{
        setLotClouser({ ...LotClouser, data: [], totalSum: 0 });
        setPaymentsVoucher({ ...PayementsVouchers, data: [], totalSum: 0 });
        setPaymentsVoucherCompleted({ ...PayementsVouchersCompleted, data: [], totalSum: 0 });
    }

    const MakeUrl =()=>{
        let complementaryurl1 = ""
        if (filter.includes("today")) {
            complementaryurl1 = "?StartDate=" + getCurrentDate() + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59&PageNumber=" + currentPage + "&PageSize=" + registPerPage;
        }
        else if (filter.includes("month")) {
            complementaryurl1 = "?StartDate=" + year() + "/" + month() + "/01" + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59&PageNumber=" + currentPage + "&PageSize=" + registPerPage;
        }
        else if (filter.includes("year")) {
            complementaryurl1 = "?StartDate=" + year() + "/01/01" + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59&PageNumber=" + currentPage + "&PageSize=" + registPerPage;
        }  
        else {    
            complementaryurl1 = specificfilter.url + "&PageNumber=" + currentPage + "&PageSize=" + registPerPage
        }
        return ({complementaryurl1})
    }

    const getLotClousures = (complementaryurl)=>{
        const OnLotClosuresRecived =(response)=>{
            const Responsedata = response.data.data;
            if (Responsedata) {
                
                setLotClouser({ ...LotClouser, data: Responsedata, totalSum: Responsedata.length});
                
            }
        }
        const OnLotClosuresFailed = (response) => {
            setLotClouser({ ...LotClouser, data: [], totalSum: 0 });
        }
        datacontext.getLotClousers(OnLotClosuresRecived,OnLotClosuresFailed,complementaryurl);
    }

    const getPhysicalCompleted =(complementaryurl)=>{
        const onPhysicalRecordCompletedRecived = (response) => {
            const Responsedata = response.data.data;
            if (Responsedata) {
                setPaymentsVoucherCompleted({ ...PayementsVouchersCompleted, data: Responsedata, totalSum: Responsedata.length});
            }
        }
        const OnPhysicalRecordCompletedFailed = (response) => {
            setPaymentsVoucherCompleted({ ...PayementsVouchersCompleted, data: [], totalSum: 0 });
        }
        datacontext.getPhysicalPaymentRecordCompleted(onPhysicalRecordCompletedRecived,OnPhysicalRecordCompletedFailed,complementaryurl);
    }

    const getPhysicalRecord =(complementary)=>{
        const onPhysicalRecordRecived = (response) => {
            const Responsedata = response.data.data;
            if (Responsedata) {
                setPaymentsVoucher({ ...PayementsVouchers, data: Responsedata, totalSum: Responsedata.length});
            }
        }
        const OnPhysicalRecordFailed = (response) => {
        setPaymentsVoucher({ ...PayementsVouchers, data: [], totalSum: 0 });
       }
        datacontext.getPhysicalPaymentRecord(onPhysicalRecordRecived,OnPhysicalRecordFailed,complementary);
    }

    



    const makeRequest = () => {
        ResetData();
        const {complementaryurl1} =MakeUrl();
        getLotClousures(complementaryurl1);
        getPhysicalCompleted(complementaryurl1);
        getPhysicalRecord(complementaryurl1);
    }

  


    useEffect(() => {
        if (hasInitialized.current) {
            makeRequest();
        }
    }, [filter, specificfilter])

    useEffect(() => {
        if (!hasInitialized.current&&!hasRealyInitialized.current) {
            setTimeout(()=>{
                hasInitialized.current = true;
            },500)
            hasRealyInitialized.current = true;
            makeRequest();
        }
    }, [])

    useEffect(() => {
        if (hasPageChanged.current) {
         const { complementaryurl1 } = MakeUrl();
         getPhysicalCompleted(complementaryurl1);
        } 
     }, [currentPage]);



    return  {
              alltransactionsactive,
              PayementsVouchers,
              PayementsVouchersCompleted,
              LotClouser,
              Pagination ,
              makeRequest,
            };

 
}
export default UseContentPhysical;