import { useContext, useEffect, useRef } from 'react';
import { Context } from '../../context/Context';
import { Button } from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { isQuiosco } from '../../permissions/permissions';

function CameraViewer({ onImageTaken }) {
  const videoRef = useRef(null);
  const canvasRef = useRef(null); // Referencia a un elemento canvas para dibujar la imagen capturada
  const streamRef = useRef(null); // Referencia al stream de video
  const datacontext = useContext(Context);

  useEffect(() => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      const facingMode = isQuiosco(datacontext?.LoggedUser, datacontext?.Sandbox) ? "user" : "environment";

      navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: facingMode // Selecciona la cámara basada en el resultado de isQuiosco
        }
      })
        .then((stream) => {
          streamRef.current = stream; // Guardar el stream en la referencia
          if (videoRef.current) {
            videoRef.current.srcObject = stream;
          }
        })
        .catch((error) => {
          console.error('Error accessing media devices.', error);
        });
    }

    return () => {
      // Limpiar el stream de video cuando el componente se desmonte
      if (streamRef.current) {
        streamRef.current.getTracks().forEach(track => track.stop());
      }
    };
  }, [datacontext]);

  const capturePhoto = () => {
    const canvas = canvasRef.current;
    const video = videoRef.current;

    if (canvas && video) {
      const context = canvas.getContext('2d');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

      // Convertir la imagen del canvas a un archivo
      canvas.toBlob((blob) => {
        const file = new File([blob], 'photo.jpg', { type: 'image/jpeg' });
        // Aquí puedes usar el archivo de la misma manera que lo hacías antes
        onImageTaken(file);
      }, 'image/jpeg');
    }
  };

  return (
    <div className='captureVerificationCtn'>
      <video ref={videoRef} autoPlay style={{ padding: "20px", top: 0, left: 0, width: '80%', height: '50%' }} />
      <canvas ref={canvasRef} style={{ display: 'none' }} /> {/* El canvas está oculto */}
      <div onClick={capturePhoto} style={{ textDecoration: 'none' }} to="/CaptureVerification" className="stylebtnTP" >
        <Button >
          <CameraAltIcon color="action" />
        </Button>
      </div>
    </div>
  );
}

export default CameraViewer;
