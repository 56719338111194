import { useEffect, useState, useContext } from "react";
import { Context } from "../../../../../../../../context/Context";

const useIncidents = ({ contentdashboard }) => {
  const datacontext = useContext(Context);
  const {Refunds, Incidents, setIncidents, makeRequest } = contentdashboard

  const [requestCompleteTransaction, setRequestCompleteTransaction] = useState({
    state: false,
    id: "",
    loading: false,
    success: null,
  });
  const [requestMoveTransaction, setRequestMoveTransaction] = useState({
    state: false,
    id: "",
    loading: false,
    success: null,
  });

  const [transactiondata, setTransactiondata] = useState({
    payload: "",
    transactionid: "",
    voucher: "",
  });

  const [transactiondata2, setTransactiondata2] = useState({
    payload: "",
    transactionid: "",
    voucher: "",
  });

  const [openPayloadViewer, setOpenPayloadViewer] = useState(false);
  const [openPayloadViewer2, setOpenPayloadViewer2] = useState(false);

  useEffect(() => {
    if (transactiondata.payload) setOpenPayloadViewer(true);
  }, [transactiondata]);

  useEffect(() => {
    if (transactiondata2.payload) setOpenPayloadViewer2(true);
  }, [transactiondata2]);


  useEffect(() => {
    if (requestCompleteTransaction.state === true) {
      setIncidents({ ...Incidents, data: [], totalSum: 0 });
      datacontext.postCompleteTransaction(
        (response) => {
          console.log(response);
          makeRequest();
          setRequestCompleteTransaction({
            state: false,
            id: "",
            loading: false,
            success:response.status === 200?true:false
          });
        },
        () => {
          console.error("Entro al false");
          makeRequest();
          setRequestCompleteTransaction({
            state: false,
            id: "",
            loading: false,
            success: false,
          });
        },
        requestCompleteTransaction.id
      );
    }
  }, [requestCompleteTransaction]);

  useEffect(() => {
    if (requestMoveTransaction.state === true) {
      setIncidents({ ...Incidents, data: [], totalSum: 0 });
      datacontext.putMoveToRefunds(
        
        (response) => {
          console.log("ok",response)
          makeRequest();
          setRequestMoveTransaction({
            state: false,
            id: "",
            loading: false,
            success:
              response.data.success
          });
        },
        (resp) => {
          console.log("error",resp)
          makeRequest();
          setRequestMoveTransaction({
            state: false,
            id: "",
            loading: false,
            success: false,
          });
        },
        requestMoveTransaction.id
      );
    }
  }, [requestMoveTransaction]);

  const handleComplete = (row) => {
    setTransactiondata({
      payload: JSON.parse(row.payload),
      transactionid: row.id,
      voucher: row.voucher,
    });
  };

  const handleComplete2 = (row) => {
    setTransactiondata2({
      payload: JSON.parse(row.payload),
      transactionid: row.id,
      voucher: row.voucher,
    });
  };



  const handleClosePayloadViewer = () => {
    setTransactiondata({ payload: "", transactionid: "", voucher: "" });
    setTransactiondata2({ payload: "", transactionid: "", voucher: "" });
    setOpenPayloadViewer(false);
    setOpenPayloadViewer2(false);
  };

  const handleCloseModal = () => {
    setRequestCompleteTransaction((prev) => ({
      ...prev,
      success: null,
      state: false,
    }));
    setRequestMoveTransaction((prev) => ({
      ...prev,
      success: null,
      state: false,
    }));
    setTransactiondata({ payload: "", transactionid: "", voucher: "" });
    setTransactiondata2({ payload: "", transactionid: "", voucher: "" });
  };

  const onFinalActionButtonClicked = (data) => {
    setRequestCompleteTransaction({
      state: true,
      id: data.transactionid,
      loading: true,
      success: null,
    });
  };
  const onScundaryActionButtonClicked = (data) => {
    setRequestMoveTransaction({
      state: true,
      id: data.transactionid,
      loading: true,
      success: null,
    });
  };
  const keysEditablesIncidents = [
    "correo",
    "telefono",
    "detalles.nombre",
    "detalles.apellido",
    "detalles.pasaporte",
  ];

  const keysVisiblesIncidents = [
    "personas",
    "importe",
    "service_charge",
    "manual",
    "correo",
    "telefono",
    "detalles",
    "coment"
  ];

  return {
    Incidents,
    Refunds,
    transactiondata,
    transactiondata2,
    openPayloadViewer,
    openPayloadViewer2,
    requestCompleteTransaction,
    requestMoveTransaction,
    keysEditablesIncidents,
    keysVisiblesIncidents,
    handleComplete,
    handleComplete2,

    handleClosePayloadViewer,
    handleCloseModal,
    onFinalActionButtonClicked,
    onScundaryActionButtonClicked,
    makeRequest,
  };
};

export default useIncidents;
