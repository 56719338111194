import { useState, useEffect, useContext } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styled from "styled-components";
import { Context } from "../../../../../../../context/Context";
import { downloadFile } from "../../../../../../../context/firebase/storageCrud";
import {
  authenticateWithEmailAndPassword,
  ConfigureFirebase,
} from "../../../../../../../context/firebase/firebaseconfig";

const PreviewImage = styled.img`
  max-width: 100%;
  height: auto;
  margin-top: 10px;
`;

const DialogHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PhysicalVoucherModal = ({ open, onClose, onSubmit, vouucherdata }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [comment, setComment] = useState("");
  const datacontext = useContext(Context);

  useEffect(() => {
    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    } else {
      setPreviewUrl(null);
    }
  }, [selectedFile]);

  const handleFileChange = (event) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleSubmit = () => {
    if (selectedFile) {
      onSubmit(selectedFile, comment, vouucherdata);
      handleClose();
    }
  };

  const handleClose = () => {
    setSelectedFile(null);
    setPreviewUrl(null);
    setComment("");
    onClose();
  };

  useEffect(() => {
    console.log(vouucherdata)
    if (vouucherdata?.imageUrl) setPreviewUrl(vouucherdata?.imageUrl);
    else {
      if (vouucherdata?.paymentGateway || vouucherdata?.dateCreate) {
        
        setPreviewUrl(null);
        const DownloadVoucher = (storage) => {
          let filepath;
          if (vouucherdata.paymentGateway) {
            filepath = datacontext.Sandbox
              ? "PhysicalVouchersSandox/" + vouucherdata?.paymentGateway
              : "PhysicalVouchers/" + vouucherdata?.paymentGateway;
          } else {
            filepath = datacontext.Sandbox
              ? "LotClousersSandox/" + vouucherdata?.dateCreate
              : "LotClousers/" + vouucherdata?.dateCreate;
          }

          const urlToFile = async (url, filename, mimeType = "image/jpeg") => {
            const response = await fetch(url);
            const blob = await response.blob();
            return new File([blob], filename, { type: mimeType });
          };

          downloadFile(filepath, storage)
          .then(async (resp) => {
            setPreviewUrl(resp);
            // Convertir la URL en un archivo
            const file = await urlToFile(resp, "downloaded-file.jpg");
            setSelectedFile(file);
          })
            .catch((e) => {
              console.log(e);
            });
        };

        const FirebaseConfigure = (res) => {
          try {
            const firebaseConfig = res.data.data.firebaseConfig;
            const firebaseUser = atob(res.data.data.userFirebase);
            const firebasePasword = atob(res.data.data.paswordFirebase);
            const { storage, auth } = ConfigureFirebase(firebaseConfig);
            authenticateWithEmailAndPassword(
              firebaseUser,
              firebasePasword,
              auth
            )
              .then(() => {
                DownloadVoucher(storage);
              })
              .catch((e) => {
                console.log(e);
              });
          } catch (e) {
            console.log("Fallo al configurar firebase", e);
          }
        };
        datacontext.getFirebaseConfig(FirebaseConfigure, FirebaseConfigure);
      }
    }
  }, [vouucherdata?.imageUrl]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogHeader>
        <DialogTitle>
          {vouucherdata?.paymentGateway
            ? "Subir imagen del voucher"
            : "Subir imagen del cierre de lote"}
        </DialogTitle>
       
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogHeader>
      <DialogContent>



        <div>
            <Typography
              variant="h6"
              component="span"
              style={{ fontWeight: "bold", fontSize: "1.0rem" }}
            >
              Código de transacción:&nbsp;
            </Typography>
            <Typography
          variant="h6"
          component="span"
          style={{ fontWeight: "bold", fontSize: "1.5rem" }}
        >
          {vouucherdata?.paymentGateway}
        </Typography>
            
          </div>

          <div style={{ marginTop: "8px" }}>
            <Typography
              variant="h6"
              component="span"
              style={{ fontWeight: "bold", fontSize: "1.0rem" }}
            >
              Monto:&nbsp;
            </Typography>
            <Typography
          variant="h6"
          component="span"
          style={{ fontWeight: "bold", fontSize: "1.5rem" }}
        >
          {"$"+vouucherdata?.totalAmount}
        </Typography>
          </div>
        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          style={{ marginTop: "10px" }}
        />
        {previewUrl && <PreviewImage src={previewUrl} alt="Previsualización" />}

        <TextField
          label="Comentario (opcional)"
          fullWidth
          multiline
          rows={4}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          style={{ marginTop: "10px" }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={!selectedFile}
        >
          Enviar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PhysicalVoucherModal;
