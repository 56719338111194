import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Principal from './components/Principal';
import NotFound from './components/NotFound';
import PrincipalMenu from './components/PrincipalMenu';
import TravelData from "./components/TravelData";
import NumberofUsers from './components/NumberofUsers';
import IdentityVerification from './components/IndentityVirification';
import CaptureVerification from './components/CaptureVerification';
import DataVerification from './components/DataVerification';
import IdentityConfirmation from './components/IndentityConfirmation';
import ConfirmationUsers from './components/ConfirmationUsers';
import TotalPayment from './components/TotalPayment';
import PaymentConfirmation from './components/PaymentConfirmation';
import AskforRecipt from './components/AskforRecipt';
import AdministrationPanel from './components/AdministrationPanel/AdministrationPanel';
import ChangePassword from './components/ChangePassword';
import ResetPassword from './components/ResetPassword';
import * as React from 'react';
import '../src/assets/styles/general.css';
import { lazy ,useContext} from 'react';
import LoggedBar from './components/Universals/LoggedBar';
import PrivacyNotice from './components/PrivacyNotice/PrivacyNotice';
import IconNavigation from './components/Universals/IconNavigation/IconNavigation';
import { Context } from './context/Context';
import CompletePayment from './components/CompletePayment/CompletePayment';
import { isQuiosco } from './permissions/permissions';
const Login = lazy(() => import(/* webpackChunkName: "LazyPage1" */'./components/Login'));

function App() {

  const dataContext = useContext(Context);
  return (
    <Router>
      <LoggedBar />
      <main
        style={{
          paddingBottom: dataContext.LoggedUser  ? '60px' : '0', // Ajusta el espacio inferior solo cuando el IconNavigation está visible
          // Asume un tamaño de 60px para LoggedBar si también está fijo en la parte superior
        }}
      >
        <Routes>
          <Route path='/' element={<Principal />} />
          <Route path='/PrincipalMenu' element={<PrincipalMenu />} />
          <Route path='/TravelData' element={<TravelData />} />
          <Route path='/NumberofUsers' element={<NumberofUsers />} />
          <Route path='/IdentityVerifications' element={<IdentityVerification />} />
          <Route path='/CaptureVerification' element={<CaptureVerification />} />
          <Route path='/DataVerification' element={<DataVerification />} />
          <Route path='/IdentityConfirmation' element={<IdentityConfirmation />} />
          <Route path='/ConfirmationUsers' element={<ConfirmationUsers />} />
          <Route path='/TotalPayment' element={<TotalPayment />} />
          <Route path='/PaymentConfirmation' element={<PaymentConfirmation />} />
          <Route path='/AskforRecipt' element={<AskforRecipt />} />
          <Route path='/Login' element={<Login />} />
          <Route path='/Dashboard' element={<AdministrationPanel />} />
          <Route path='/ChangePassword' element={<ChangePassword />} />
          <Route path='/ResetPassword' element={<ResetPassword />} />
          <Route path='/PrivacyNotice' element={<PrivacyNotice />} />
          <Route path ='/CompletePayment' element={<CompletePayment />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </main>

      {/* Mostrar IconNavigation solo cuando showNavigation es true */}
      {(dataContext.LoggedUser != null && !isQuiosco(dataContext?.LoggedUser, dataContext?.Sandbox)) && <IconNavigation />}
    </Router>
  );
}

export default App;
