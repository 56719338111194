import { useContext, useEffect, useState } from "react";
import { Context } from "../../../../../../../../context/Context";

const useAuthorization = ({ onAutorizationDone, onClose }) => {
  const datacontext = useContext(Context);
  const [usersList, setUsersList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [AuthError, setAuthError] = useState(false);

  const handleUserChange = (event, value) => {
    setSelectedUser(value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleAuthorize = () => {
    setLoading(true);
    validateuser();
  };

  useEffect(() => {
    const onokResponse = (response) => {
      console.log(response);
      const users = response.data.data;
      const filterAndTransformUsers = (users) => {
        return users
          .filter((user) => user.role === "Supervisor" && user.userName.includes('itedesca'))
          .map((user) => ({
            fullname: `${user.name} ${user.lastName} ${user.motherLastName} ${user.fatherLastName}`,
            id: user.id,
          }));
      };

      const filteredUsers = filterAndTransformUsers(users);
      setUsersList(filteredUsers);
    };
    const onfailResponse = () => {
      setUsersList([]);
    };
    datacontext.getUsers(onokResponse, onfailResponse);
  }, [datacontext]);

  const validateuser = () => {
    const onokResponse = (response) => {
      console.log("okresponse", response);
      onAutorizationDone({ state: true, user: selectedUser });
      setLoading(false);
      setAuthError(false);
    };
    const onfailResponse = (response) => {
      console.log("failresponse", response);
      if (response) {
        onAutorizationDone({ state: false, user: selectedUser });
        setLoading(false);
        setAuthError(true);
      }
    };
    datacontext.postValidateUser(onokResponse, onfailResponse, {
      idUser: selectedUser.id,
      password: password,
    });
  };

  return {
    setAuthError,
    AuthError,
    usersList,
    password,
    loading,
    selectedUser,
    handleUserChange,
    handleAuthorize,
    handlePasswordChange,
    validateuser,
  };
};
export default useAuthorization;
