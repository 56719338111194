import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Button, Grid } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { Context } from "../../context/Context";
import { Datos } from "../../data/Datos";
import LenguageSelector from "../Universals/LenguageSelector";
import ConstructionPage from "./ConstructionPage";
import "../../assets/styles/general.css";
import arrow from "../../assets/Images/arrow-inicio.png";
import LogoVisitaxAvionColor from "../../assets/Images/LogoVisitaxAvionColor.png";
import FAQ from "./FAQ/FAQ";
import satqLogo from "../../assets/Images/LOGO-SATQ.png";
import sefiplanb from "../../assets/Images/SEFIPLANBorderless.png";
import quintanaroo from "../../assets/Images/quintanaroo.png";

function Principal() {
  const datacontext = useContext(Context);
  const txt = datacontext.myConfig.txt[datacontext.lenguage];
  const [mantainment, setmanteiniment] = useState(0);
  const isLogin = datacontext.LoggedUser;

  useEffect(() => {
    if (datacontext.Data.Datos.detalles !== Datos.detalles)
      datacontext.ResetData();
  }, [datacontext.Data.Datos.detalles]);

  useEffect(() => {
    if (datacontext.myConfig.allconfigArray) {
      try {
        datacontext.myConfig.allconfigArray.map((value) => {
          if (value.concept === "MaintenanceActive") {
            setmanteiniment(Number(value.configurationInfo));
          }
        });
      } catch (e) {
        console.log(e);
      }
    }
  }, [datacontext.myConfig]);

  useEffect(() => {
    datacontext.getconfigurations(true);
  }, []);

  return (
    <>
      <div className="cancunbackground">
        {mantainment === 1 ? (
          <ConstructionPage />
        ) : (
          <>
            <ThemeProvider theme={datacontext.primaytheme}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <LenguageSelector />
                </Grid>
                {!isLogin ? (
                  <Grid item>
                    <Button href="/Login" sx={{padding: '15px', border: '1px solid #000000DE', color: '#000000DE'}}>
                      {txt[60]}
                    </Button>
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>
            </ThemeProvider>
            <img
              className="logovisitax"
              src={LogoVisitaxAvionColor}
              alt="Visitax logo"
            ></img>
            <Link
              style={{ textDecoration: "none" }}
              to="/PrincipalMenu"
              className="stylebtn1"
            >
              {txt[1]} <img id="continue" src={arrow} alt="Visitax logo"></img>
            </Link>
          </>
        )}
      </div>
      <div className="PrincipalInfoVisitax">
        <div className="principalTxtInfo">
          <div className="principalTxt">
            <p>{txt[78]}</p>
          </div>
          <div className="principalTxtArticle">
            <p>{txt[79]}</p>
            <p>{txt[80]}</p>
          </div>
        </div>
        <div className="principalTxtCtn">
          <p>{txt[81]}</p>
        </div>
        <FAQ />
        {/* <div className="principalImgCtn">
          <img src={sefiplanb} className="principalImg" alt="" />
          <img src={satqLogo} className="principalImg" alt="" />
          <img src={quintanaroo} className="principalImg" alt="" />
        </div> */}
      </div>
    </>
  );
}

export default Principal;
