import { useState, useEffect, useContext } from "react";
import { Box, Grid, TextField, Button, IconButton } from "@mui/material";
import {
  SandboxprofilesId,
  ProductionprofilesId,
  ListOfRolesKeys,
} from "../../../../../../permissions/permissions";
import Autocomplete from "@mui/material/Autocomplete";
import PasswordIcon from '@mui/icons-material/Password';
import {
  SaveAlertDialog,
  SuccesUploadAlertDialog,
  DeleteDialog,
} from "../../../../../Universals/Dialogs";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import { Context } from "../../../../../../context/Context";
import PersonIcon from "@mui/icons-material/Person";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import ChangeUserPassword from "./ChangeUserPasword";

const EditableUser = ({ CopyRow, setData }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [DeleteopenDialog, setDeleteOpenDialog] = useState(false);
  const [openDialogSucces, setOpenDialogSucces] = useState({open:false,fail:false});
  const [savingchanges, setsavingchanges] = useState(false);
  const [deletinguser, setdeletinguser] = useState(false);
  const [copyRow, setCopyRow] = useState(CopyRow);
  const [inputValue, setInputValue] = useState(CopyRow.role);
  const [role, setRole] = useState(CopyRow.role);
  const datacontext = useContext(Context);
  const [dialogOpenpassword, setDialogOpenpassword] = useState(false);
  const OnDataSaved = () => {
    setOpenDialogSucces({open:true,fail:false});
  };
  const OnDataFailed = () => {
    setsavingchanges(false);
    setOpenDialogSucces({open:true,fail:true});
  };

  useEffect(() => {
    if (savingchanges)
      datacontext.putUser(OnDataSaved, OnDataFailed, {
        ...copyRow,
        idRole: datacontext?.Sandbox
          ? SandboxprofilesId[inputValue]
          : ProductionprofilesId[inputValue],
      });
  }, [savingchanges]);

  useEffect(() => {
    if (deletinguser)
      datacontext.putUser(OnDataSaved, OnDataFailed, {
        ...CopyRow,
        isActive: !copyRow.isActive,
      });
  }, [deletinguser]);
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setOpenDialog(true);
  };
  useEffect(() => {
    console.log(CopyRow);
  }, []);
  const handleOpenDialog = () => {
    setDialogOpenpassword(true);
  };

  const handleCloseDialog = () => {
    setDialogOpenpassword(false);
  };

  const handleAgree = (password) => {
    datacontext.postChangePassword(OnDataSaved,OnDataFailed,
     {
      user: copyRow.userName,
      password: password,
    });
    setDialogOpenpassword(false);
  };

  
  return (
    <>
      <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
        <SaveAlertDialog
          open={openDialog}
          setOpen={setOpenDialog}
          setsavingchanges={setsavingchanges}
        />
        <ChangeUserPassword
        open={dialogOpenpassword}
        onClose={handleCloseDialog}
        onAgree={handleAgree}
      />
        <DeleteDialog
          open={DeleteopenDialog}
          setOpen={setDeleteOpenDialog}
          setsavingchanges={setdeletinguser}
        />
        <SuccesUploadAlertDialog open={openDialogSucces.open} setOpen={setData} fail={openDialogSucces.fail} />

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              onChange={(e) => {
                setCopyRow({
                  ...copyRow,
                  name: e.target.value,
                });
              }}
              required
              margin="dense"
              id="name"
              name="name"
              label="Name"
              type="text"
              value={copyRow.name}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              onChange={(e) => {
                setCopyRow({
                  ...copyRow,
                  lastName: e.target.value,
                });
              }}
              margin="dense"
              id="lastname"
              name="lastname"
              label="Second Name"
              type="text"
              value={copyRow.lastName}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              onChange={(e) => {
                setCopyRow({
                  ...copyRow,
                  fatherLastName: e.target.value,
                });
              }}
              required
              margin="dense"
              id="FatherLastName"
              name="FatherLastName"
              label="Father's Lastname"
              type="text"
              value={copyRow.fatherLastName}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              onChange={(e) => {
                setCopyRow({
                  ...copyRow,
                  motherLastName: e.target.value,
                });
              }}
              required
              margin="dense"
              id="MotherLastName"
              name="MotherLastName"
              label="Mother's Lastname"
              value={copyRow.motherLastName}
              type="text"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              onChange={(e) => {
                setCopyRow({
                  ...copyRow,
                  userName: e.target.value,
                });
              }}
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              value={copyRow.userName}
              autoComplete="email"
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              value={role}
              onChange={(event, newValue) => {
                setRole(newValue);
              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              id="controllable-states-demo"
              options={ListOfRolesKeys}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Select a role" />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => {
                setDeleteOpenDialog(true);
              }}
            >
              {copyRow.isActive ? <PersonOffIcon /> : <PersonIcon />}
              {copyRow.isActive ? "Desactivar Usuario" : "Activar Usuario"}
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={handleOpenDialog}
            >
              <PasswordIcon />
              Cambiar Contraseña
            </IconButton>
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          <SaveIcon /> Guardar
        </Button>
      </Box>
    </>
  );
};
export default EditableUser;
