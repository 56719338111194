import React, { useState, useEffect,useRef } from "react";
import {
  Table as MUITable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TextField,
  Pagination,
  Skeleton,
  Button,
  Box,
  Chip,
} from "@mui/material";

const Title = ({ title = "" }) => {
  return (
    <Typography
      component="h2"
      variant="h6"
      color="primary"
      align="center"
      gutterBottom
    >
      {title}
    </Typography>
  );
};

const Table = ({
  headers,
  content = [],
  totalElements,
  onCellClick = () => {},
  onPageChange = () => {},
  onPageChangeFilterd = () => {},
  actionButtonTitle,
  onActionButtonClick = () => {},
  omitKeys = [],
  title = "",
  standardPagination = true,
  filters = [],
  onSearch = () => {},
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [paginatedContent, setPaginatedContent] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(filters[0] || "");
  const haslookfiltered = useRef(false);
  const itemsPerPage = 10;

  useEffect(() => {
    setPaginatedContent(content);
    setLoading(false);
  }, [content]);

  useEffect(() => {
    if(standardPagination){
        if (searchTerm === "") {
            setPaginatedContent(content);
          } else {
            const filtered = content.filter((row) => {
              return Object.entries(row).some(([key, value]) => {
                if (omitKeys.includes(key) || value === null) return false;
                return value
                  .toString()
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase());
              });
            });
            setPaginatedContent(filtered);
          }
          setCurrentPage(1)
    }
   
  }, [searchTerm, content, omitKeys]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    if (!standardPagination) {
      setLoading(true);
      //console.log(haslookfiltered.current);
      if(haslookfiltered.current)onPageChangeFilterd({page:value,filter:{ filter: selectedFilter, searchTerm }});
      else onPageChange(value);
   
     
    }
  };

  const handleSearch = () => {
    setLoading(true);
    if(searchTerm === "") haslookfiltered.current = false
    else haslookfiltered.current = true
    onSearch({ filter: selectedFilter, searchTerm });

  };

  return (
    <Paper sx={{ borderRadius: 2, overflow: "hidden" }}>
      <Title title={title} />
      {standardPagination ? (
        <TextField
          label="Buscar"
          variant="outlined"
          fullWidth
          margin="normal"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, p: 2 }}>
          <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
            {filters.map((filter, index) => (
              <Chip
                key={index}
                label={filter}
                color={selectedFilter === filter ? "primary" : "default"}
                onClick={() => setSelectedFilter(filter)}
                sx={{ cursor: "pointer" }}
              />
            ))}
          </Box>
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <TextField
              label="Buscar"
              variant="outlined"
              fullWidth
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter"&&!standardPagination) {
                  handleSearch();
                }
              }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleSearch}
            >
              Buscar
            </Button>
          </Box>
        </Box>
      )}
      <TableContainer component={Paper}>
        {content.length === 0 ? (
          <Typography variant="h6" align="center" sx={{ padding: 2 }}>
            No hay datos disponibles
          </Typography>
        ) : (
          <MUITable sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                {headers.map((header, index) => (
                  <TableCell
                    key={index}
                    sx={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }}
                  >
                    {header}
                  </TableCell>
                ))}
                {actionButtonTitle && (
                  <TableCell
                    sx={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }}
                  >
                    Acción
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  {headers.map((_, index) => (
                    <TableCell key={index}>
                      <Skeleton variant="text" />
                    </TableCell>
                  ))}
                  {actionButtonTitle && (
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                  )}
                </TableRow>
              ) : (
                (standardPagination
                  ? paginatedContent.slice(
                      (currentPage - 1) * itemsPerPage,
                      currentPage * itemsPerPage
                    )
                  : paginatedContent
                ).map((row, rowIndex) => (
                  <TableRow
                    key={rowIndex}
                    onClick={() => onCellClick(row)}
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "#e0f7fa",
                      },
                      backgroundColor:
                        rowIndex % 2 === 0 ? "#ffffff" : "#f9f9f9",
                    }}
                  >
                    {Object.keys(row)
                      .filter((key) => !omitKeys.includes(key))
                      .map((key) => (
                        <TableCell key={key}>
                          {typeof row[key] === "boolean" ? (
                            <Box
                              sx={{
                                width: 12,
                                height: 12,
                                borderRadius: "50%",
                                backgroundColor: row[key]
                                  ? "#1E3A8A"
                                  : "#93C5FD",
                                display: "inline-block",
                              }}
                            />
                          ) : (
                            row[key]
                          )}
                        </TableCell>
                      ))}
                    {actionButtonTitle && (
                      <TableCell>
                        <Button
                          variant="outlined"
                          color="primary"
                          sx={{
                            padding: "4px 8px",
                            margin: "0",
                            "&:hover": {
                              backgroundColor: "#e3f2fd",
                            },
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            onActionButtonClick(row);
                          }}
                        >
                          {actionButtonTitle}
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                ))
              )}
            </TableBody>
          </MUITable>
        )}
      </TableContainer>
      {content.length > 0 && (
        <Pagination
          count={
            standardPagination
              ? Math.ceil(paginatedContent.length / itemsPerPage)
              : Math.ceil(totalElements / itemsPerPage)
          }
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
          sx={{ justifyContent: "center", margin: "16px 0" }}
        />
      )}
    </Paper>
  );
};

export default Table;
