import React, { useState, useContext, useEffect, useRef } from "react";
import "./FAQ.css";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { Context } from "../../../context/Context";

const FAQ = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const datacontext = useContext(Context);
  const txt = datacontext.myConfig.txt[datacontext.lenguage];
  const cardRefs = useRef([]);

  const questions = [
    {
      title: `${txt[84]}`,
      description: `${txt[85]}`
    },
    {
      title: `${txt[86]}`,
      description: `${txt[87]}`
    },
    {
      title: `${txt[88]}`,
      description: `${txt[89]}`
    },
    {
      title: `${txt[90]}`,
      description: `${txt[91]}`
    },
    {
      title: `${txt[92]}`,
      description: `${txt[93]}`
    },
    {
      title: `${txt[94]}`,
      description: `${txt[95]}`
    },
    {
      title: `${txt[96]}`,
      description: `${txt[97]}`
    },
    {
      title: `${txt[98]}`,
      description: `${txt[99]}`
    },
    {
      title: `${txt[100]}`,
      description: `${txt[101]}`
    },
  ];

  const questionsRight = [
    {
      title: `${txt[102]}`,
      description: `${txt[103]}`
    },
    {
      title: `${txt[104]}`,
      description: `${txt[105]}`
    },
    {
      title: `${txt[106]}`,
      description: `${txt[107]}`
    },
    {
      title: `${txt[108]}`,
      description: `${txt[109]}`
    },
    {
      title: `${txt[110]}`,
      description: `${txt[111]}`
    },
    {
      title: `${txt[112]}`,
      description: `${txt[113]}`
    },
    {
      title: `${txt[114]}`,
      description: `${txt[115]}`
    },
    {
      title: `${txt[116]}`,
      description: `${txt[117]}`
    },
    {
      title: `${txt[118]}`,
      description: `${txt[119]}`
    },
  ];

  const handleToggle = (index, listId) => {
    const combinedIndex = `${listId}-${index}`;
    setExpandedIndex(expandedIndex === combinedIndex ? null : combinedIndex);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          }
        });
      },
      { threshold: 0.1 }
    );

    cardRefs.current.forEach((card) => {
      if (card) observer.observe(card);
    });

    return () => {
      cardRefs.current.forEach((card) => {
        if (card) observer.unobserve(card);
      });
    };
  }, []);

  return (
    <div id="FAQCtn">
      <div className="FAQ-txt-ctn">
        <p className="FAQ-title">{txt[82]}</p>
        <p className="FAQ-info">{txt[83]}</p>
      </div>
      <div className="FAQ-container">
        <div className="info-card-container">
          {questions.map((question, index) => (
            <div
              className="info-card"
              key={index}
              ref={(el) => (cardRefs.current[index] = el)}
            >
              <div className="info-header">
                <span
                  className="toggle-icon"
                  onClick={() => handleToggle(index, "left")}
                >
                  {expandedIndex === `left-${index}` ? (
                    <FaChevronUp />
                  ) : (
                    <FaChevronDown />
                  )}
                </span>
                <h2 className="info-title">{question.title}</h2>
              </div>
              {expandedIndex === `left-${index}` && (
                <p className="info-description">{question.description}</p>
              )}
            </div>
          ))}
        </div>
        <div className="lineFAQ"></div>
        <div className="info-card-container-right">
          {questionsRight.map((question, index) => (
            <div
              className="info-card"
              key={index}
              ref={(el) => (cardRefs.current[questions.length + index] = el)}
            >
              <div className="info-header">
                <span
                  className="toggle-icon"
                  onClick={() => handleToggle(index, "right")}
                >
                  {expandedIndex === `right-${index}` ? (
                    <FaChevronUp />
                  ) : (
                    <FaChevronDown />
                  )}
                </span>
                <h2 className="info-title">{question.title}</h2>
              </div>
              {expandedIndex === `right-${index}` && (
                <p className="info-description">{question.description}</p>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
