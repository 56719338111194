import * as React from "react";
import { useState, useContext, useEffect } from "react";
import { Context } from "../../../context/Context";
import SimpleBackdrop from "../../Universals/BackDrop";
import Box from "@mui/material/Box";
import UsersTable from "./components/UsersTable/UsersTable";
import RegistUser from "./components/RegistUser/RegistUser";
import { Container } from "@mui/material";


function Users() {
  const datacontext = useContext(Context);
  const [data, setData] = useState("null");
  const [open, setOpen] = React.useState(false);

  const OnDataRecived = (response) => {
    console.log(response);
    if (response.data.data == null) setData("Empty");
    else setData(response.data.data);
  };
  const OnDataFailed = (response) => {};
  useEffect(() => {
    if (data == null) {
      datacontext.getUsers(OnDataRecived, OnDataFailed);
      console.log("Pidiendo Usuarios");
    }
  }, [data]);

  useEffect(() => {
    setData(null);
  }, []);

  return (
    <>
   
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            overflow: "auto",
          }}
        >
          <Container
            maxWidth={false}
            sx={{ mt: 4, mb: 4, px: { xs: 2, sm: 3, md: 4, lg: 5 } }}
          >
            {data == null ? (
              <SimpleBackdrop />
            ) : (
              <>
                <Box sx={{ marginTop: 2 }}>
                  <UsersTable data={data} setOpen={setOpen} setData={setData} />
                  <RegistUser open={open} setOpen={setOpen} setData={setData} />
                </Box>
              </>
            )}
          </Container>
        </Box>

    </>
  );
}
export default Users;
