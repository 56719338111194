import { Grid, IconButton, Box } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";

import InfoCard from "./Components/InfoCard/InfoCard";
import useContentDashboard from "./hooks/useContentDashboard";
import CustomLineChart from "./Components/Charts/LinearChart/LinearChart";
import BarChar from "./Components/Charts/BarChart/BarChar";
import MultipleBarChar from "./Components/Charts/BarChart/MultipleBarchar";
import { useEffect, useState } from "react";

const ContentDashboard = ({ filter = "today", specificfilter = null }) => {
  const {
    SellTimeChart,
    TerminalObj,
    TransactionsUsersOBJ,
    DashboardAmounts,
    makeRequest
  } = useContentDashboard({ filter, specificfilter });

  const [xaxislabel, setxaxislabel] = useState("Horas");

  useEffect(() => {
    if (filter.includes("today")) {
      setxaxislabel("Horas");
    } else if (filter.includes("month")) {
      setxaxislabel("Fechas");
    } else if (filter.includes("year")) {
      setxaxislabel("Meses");
    } else {
      setxaxislabel("Tiempo");
    }
  }, [filter]);

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
        <IconButton color="primary" onClick={makeRequest} aria-label="refresh">
          <RefreshIcon />
        </IconButton>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={3}>
          <InfoCard element={DashboardAmounts.Transactions} />
        </Grid>

        <Grid item xs={12} md={6} lg={3}>
          <InfoCard element={DashboardAmounts.NumberofPersons} />
        </Grid>

        <Grid item xs={12} md={4} lg={3}>
          <InfoCard element={DashboardAmounts.Deposits} />
        </Grid>

        <Grid item xs={12} md={4} lg={3}>
          <InfoCard element={DashboardAmounts.Taxes} />
        </Grid>

        <Grid item xs={12} md={4} lg={3}>
          <InfoCard element={DashboardAmounts.SatQAmount} />
        </Grid>

        <Grid item xs={12} md={4} lg={3}>
          <InfoCard element={DashboardAmounts.TaxesDRAmount} />
        </Grid>

        <Grid item xs={12} md={4} lg={3}>
          <InfoCard element={DashboardAmounts.ServiceCharge} />
        </Grid>

        <Grid item xs={12} md={4} lg={3}>
          <InfoCard element={DashboardAmounts.Income} />
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <CustomLineChart
            title="Gráfica de depósitos"
            description=""
            xAxisLabel={xaxislabel}
            yAxisLabel="$"
            data={SellTimeChart}
          />
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <BarChar
            title="Ventas por terminal"
            data={TerminalObj}
            height={200}
          />
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <MultipleBarChar
            title="Ventas por asesor"
            height={200}
            data={TransactionsUsersOBJ}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ContentDashboard;
