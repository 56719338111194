import usePreTransactions from "./hooks/usePretransactions";
import PayloadViewer from "../PayloadModal/PayloadViewer";
import { Grid, ToggleButton, ToggleButtonGroup } from "@mui/material";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import TapAndPlayIcon from "@mui/icons-material/TapAndPlay";
import PaymentIcon from "@mui/icons-material/Payment";
import Table from "../Table/Table";
import { useState,useEffect } from "react";

const Pretransactions = ({ contentdashboard }) => {
  const {
    PreTransactionsList,
    pretransactiondata,
    openPayloadViewer,
    keysEditablesTransactions,
    keysVisiblesTransactions,
    handleTransactionSelected,
    handleClose,
    handlePageChange,
  } = usePreTransactions({ contentdashboard });

  const [filterPayments, setFilterPayments] = useState("all");

  const handleFilterChange = (event, newFilter) => {
    console.log("Cambio");
    console.log("newFilter",newFilter);
    if (newFilter !== null) setFilterPayments(newFilter);
    switch (filterPayments) {
      case "all":
        contentdashboard.setType(1);
        break;
      case "manual":
        contentdashboard.setType(2);
        break;
      case "non-manual":
        contentdashboard.setType(3);
        break;
      default:
        break;
    }
  };

  let filteredTransactions;
  if (contentdashboard.alltransactionsactive()) {
    filteredTransactions = PreTransactionsList.data.filter((transaction) => {
      if (filterPayments === "all") return true;
      if (filterPayments === "manual") return transaction.isCompleted;
      if (filterPayments === "non-manual") return !transaction.isCompleted;
    });
  } else {
    filteredTransactions = PreTransactionsList.data;
  }

  useEffect(() => {
    console.log("Filter Payments Updated:", filterPayments);
  
    // Actualizar el estado en función del filtro actual
    switch (filterPayments) {
      case "all":
        contentdashboard.setType(1);
        break;
      case "manual":
        contentdashboard.setType(2);
        break;
      case "non-manual":
        contentdashboard.setType(3);
        break;
      default:
        break;
    }
  }, [filterPayments]);
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ToggleButtonGroup
            value={filterPayments}
            exclusive
            onChange={handleFilterChange}
            aria-label="Filtro de pretransacciones"
          >
            <ToggleButton value="all" aria-label="Mostrar todas">
              <FormatListBulletedIcon />
            </ToggleButton>
            <ToggleButton value="manual" aria-label="Mostrar solo manuales">
              <PaymentIcon />
            </ToggleButton>
            <ToggleButton
              value="non-manual"
              aria-label="Mostrar solo no manuales"
            >
              <TapAndPlayIcon />
            </ToggleButton>
          </ToggleButtonGroup>

          <Table
            headers={PreTransactionsList.headers && PreTransactionsList.headers}
            content={filteredTransactions}
            totalElements={PreTransactionsList.totalSum}
            omitKeys={PreTransactionsList.omitkeys}
            onPageChange={(page) => {
              contentdashboard.setFilteredPagination(null);
              setTimeout(() => {
                handlePageChange(page);
              }, 500);
            }}
            onPageChangeFilterd={(values) => {
              contentdashboard.setFilteredPagination(values.filter);
              setTimeout(() => {
                handlePageChange(values.page);
              }, 500);
            }}
            title="Pre-Transacciones"
            filters={[
              "Personas",
              "Cantidad",
              "Cargo por servicio",
              "Terminal",
              "Asesor",
            ]}
            onSearch={(search) =>
              contentdashboard.SerchByPretransaction(search)
            }
            standardPagination={contentdashboard.alltransactionsactive()}
            actionButtonTitle="Detalles"
            onActionButtonClick={handleTransactionSelected}
          />
        </Grid>
      </Grid>

      {pretransactiondata.payload && (
        <PayloadViewer
          open={openPayloadViewer}
          onClose={handleClose}
          transactiondata={pretransactiondata}
          keysEditables={keysEditablesTransactions}
          keysVisibles={keysVisiblesTransactions}
          finalactionbuttondisable={true}
          editbuttondisable={true}
        />
      )}
    </>
  );
};
export default Pretransactions;
