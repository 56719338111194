import React from "react";
import { useState,useContext } from "react";
import { TableRow,TableCell,IconButton } from "@mui/material";
import AttributionIcon from "@mui/icons-material/Attribution";
import Collapse from "@mui/material/Collapse";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import EditableUser from "./EditableUser";
import { Context } from "../../../../../../context/Context";


const UsersTableRow=({row,SetData})=> {
    const datacontext = useContext(Context);
    const [open, setOpen] = useState(false);

  
  
    return (
      <React.Fragment>

        <TableRow>
          <TableCell>
            {datacontext.LoggedUser.rol.includes("Admin") ? (
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <ModeEditIcon />}
              </IconButton>
            ) : (
              <></>
            )}
          </TableCell>
  
          <TableCell align="center">{row?.cons}</TableCell>
          <TableCell align="center">{row?.user?.role}</TableCell>
  
          <TableCell align="center">
            {row?.user?.name +
              " " +
              row?.user?.lastName +
              " " +
              row?.user?.fatherLastName +
              " " +
              row?.user?.motherLastName}
          </TableCell>
          <TableCell align="center">{row?.user?.userName}</TableCell>
          <TableCell align="center">
            {
              <AttributionIcon
                color={row?.user?.isActive ? "success" : "disabled"}
              />
            }
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
            <Collapse in={open} timeout="auto" unmountOnExit>
            <EditableUser CopyRow={{...row?.user}}  setData={SetData}/>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
  export default UsersTableRow