import * as React from "react";
import { Context } from "../../../../../context/Context";
import { useState, useContext, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import Box from "@mui/material/Box";

import { Button, Container } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";

import TextField from "@mui/material/TextField";

import UsersTableRow from "./componets/UsersTableRow";

function createData(cons, user) {
  return { cons, user };
}



function UsersTable({ data, setOpen, setData }) {
  const datacontext = useContext(Context);

  // Estado para la barra de búsqueda
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(data);

  // Filtrar datos cada vez que cambie el texto de búsqueda o los datos
  useEffect(() => {
    const lowercasedSearchText = searchText.toLowerCase();
    try {
      if (data) {
        setFilteredData(
          data?.filter((user) =>
            [
              user?.name +
                " " +
                user?.lastName +
                " " +
                user?.fatherLastName +
                " " +
                user?.motherLastName,
              user?.role,
              user?.userName,
            ].some((field) =>
              field.toLowerCase().includes(lowercasedSearchText)
            )
          )
        );
      }
    } catch (e) {}
  }, [searchText, data]);
  let rows = null;
  try {
    rows = filteredData.map((value, id) => createData(id + 1, value));
  } catch (e) {}

  return (
    <>
      <Paper sx={{ borderRadius: 2, overflow: "hidden" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
          <TextField
            label="Buscar por nombre, rol o correo"
            variant="outlined"
            fullWidth
            onChange={(e) => setSearchText(e.target.value)}
          />
          {datacontext?.LoggedUser?.rol.includes("Admin") && (
            <IconButton
              aria-label="add user"
              onClick={() => setOpen(true)}
              sx={{ ml: 2 }}
            >
              <AddCircleOutlineIcon />
            </IconButton>
          )}
        </Box>
        <TableContainer
          component={Paper}
          sx={{
            maxHeight: "calc(100vh - 200px)",
            overflowX: "auto", // Para scroll horizontal
          }}
        >
          <Table aria-label="collapsible table" sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="center">Cons</TableCell>
                <TableCell align="center">Rol</TableCell>
                <TableCell align="center">Nombre</TableCell>
                <TableCell align="center">Correo</TableCell>
                <TableCell align="center">Activo</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows && (
                <>
                  {rows.map((row, id) => (
                    <UsersTableRow
                      key={id}
                      row={row}
                      SetData={() => setData(null)}
                    />
                  ))}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}

export default UsersTable;
