import { useState, useEffect } from "react";


const usePreTransactions = ({contentdashboard}) => {
  const {
    PreTransactionsList,
    Pagination,
  } = contentdashboard;

  const [pretransactiondata, seTransactiondatavoucher] = useState({
    payload: "",
    transactionid: "",
    voucher: "",
    paymentgateway: "",
    imageurl:"",
  });

  const [openPayloadViewer, setopenPayloadViewer] = useState(false);


  useEffect(() => {
    if (pretransactiondata.payload) setopenPayloadViewer(true);
  }, [pretransactiondata]);

  const handleTransactionSelected = (row) => {
    seTransactiondatavoucher({
      payload: JSON.parse(row.payload),
      transactionid: row.id,
      voucher: row.voucher,
      paymentgateway: row.openpayKey,
      imageurl:row.imageUrl
    });
  };

  const handleClose = () => {
    seTransactiondatavoucher({
      payload: "",
      transactionid: "",
      voucher: "",
      paymentgateway: "",
      imageurl:""
    });
    setopenPayloadViewer(false);
  };

 

  const handlePageChange = (newPage) => {
    Pagination(newPage);
  };

  const keysEditablesTransactions = [];
  const keysVisiblesTransactions = [
    "personas",
    "importe",
    "service_charge",
    "manual",
    "correo",
    "telefono",
    "detalles",
    "coment"
  ];

  return {
    PreTransactionsList,
    pretransactiondata,
    openPayloadViewer,
    keysEditablesTransactions,
    keysVisiblesTransactions,
    handleTransactionSelected,
    handleClose,
    handlePageChange,
  };
};

export default usePreTransactions;
