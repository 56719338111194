
import { useState,useEffect,useRef ,useContext} from "react";
import { Context } from "../../../../../context/Context";
import { getCurrentDate,month,year } from "../../../../../data/Datos";
const useContentReports = ({ filter = "today", specificfilter = null })=>{
  const datacontext = useContext(Context);
  const hasInitialized = useRef(false);
  const hasRealyInitialized = useRef(false);
  const [SellTimeChart, setSellTimeChartData] = useState([]);
  const [TransactionsList, setTransactionsList] = useState({
          headers: ["Voucher", "Personas ", "Cantidad", "Cargo por servicio", "Codigo de transacción", "Asesor", "Terminal", "Fecha de partida", "Fecha de creación","Manual","Folio"],
          data: [],
          totalSum: 0,
          omitkeys: ["id", "updatedAt", "payment", "dateSatQ", "hourSatQ", "useridSatQ", "idSatQ", "payload","comments","datecreateMex","imageUrl"]
      });
   const [TerminalObj, setTerminalObject] = useState({});
   const [Dates, setDates] = useState({ EndDate: "", StartDate: "" });
   const [TransactionDetailsList, setTransactionDetailList] = useState({
           headers: ["Pasaporte", "Cargo por servicio", "Cantidad", "Codigo QR", "Voucher", "Fecha de Partida", "Fecha de creación","Fecha de creación Mex"],
           data: [],
           totalSum: 0,
           omitkeys: ["id"]
       });
    const [TableID,setTableId]=useState("Horas");
    const [DashboardAmounts, setDashboardAmounts] = useState({
            Deposits:{ Title: "Total de ingresos", Value: "$-", SecundaryValue: "Cantidad de ingresos totales" ,numericvalue :0 },
            ServiceCharge:{ Title: "Service Charge", Value: "$-", SecundaryValue: "Total cargos por servicio", numericvalue :0 },
            SatQAmount:{ Title: "Pago a SATQ", Value: "$-", SecundaryValue: "1.88 UMA'S", numericvalue :0 },
            TaxesDRAmount:{ Title: "Comisión DR", Value: "$-", SecundaryValue: "0.72 UMA'S", numericvalue :0 },
            Income:{ Title: "Utilidad Bruta", Value: "$-", SecundaryValue: "Comisión DR + Service Charge",numericvalue :0 },
            Transactions:{ Title: "Transacciones", Value: "-", SecundaryValue: "Total de transacciones" ,numericvalue :0},
            NumberofPersons:{ Title: "PAX", Value: "-", SecundaryValue: "Total de personas" ,numericvalue :0},
            Taxes:{ Title: "Cobro a SATQ", Value: "-", SecundaryValue: "2.5 UMAS",numericvalue :0 },
   })

   const [PayementsVouchers, setPaymentsVoucher] = useState({
    headers: ["Codigo de transacción","Cantidad", "Descripción","Usuario","Fecha Cancun","Fecha Mex"],
    data: [],
    totalSum: 0,
    omitkeys: ["id","idTransaction","lotClosing","imageUrl","idUser"]
});

const [PayementsVouchersCompleted, setPaymentsVoucherCompleted] = useState({
    headers: ["Codigo de transacción", "Cantidad","Descripción","Usuario","Fecha Cancun","Fecha Mex"],
    data: [],
    totalSum: 0,
    omitkeys: ["id","idTransaction","lotClosing","imageUrl","idUser"]
});




   const ResetData = ()=>{
           setPaymentsVoucher({ ...PayementsVouchers, data: [], totalSum: 0 });
           setPaymentsVoucherCompleted({ ...PayementsVouchersCompleted, data: [], totalSum: 0 });
           setTransactionsList({ ...TransactionsList, data: [], totalSum: 0 ,numericvalue:0});
           setTransactionDetailList({ ...TransactionDetailsList, data: [], totalSum: 0 })
           setSellTimeChartData([])
           setTerminalObject({});
           setDashboardAmounts((prev) =>( 
               {Deposits:{ ...prev.Deposits, Value: "$-",numericvalue :0 },
              ServiceCharge:{...prev.ServiceCharge, Value: "$-",numericvalue :0 },
              SatQAmount:{ ...prev.SatQAmount, Value: "$-",numericvalue :0 },
              TaxesDRAmount:{ ...prev.TaxesDRAmount, Value: "$-",numericvalue :0 },
              Income:{...prev.Income, Value: "$-",numericvalue :0 },
              Transactions:{ ...prev.Transactions, Value: "-",numericvalue :0},
              NumberofPersons:{ ...prev.NumberofPersons, Value: "-",numericvalue :0},
              Taxes:{  ...prev.Taxes, Value: "$-",numericvalue :0}}))
   
   
       }

       
    const getTransactions = (complementaryurl)=>{
        const OnDataRecived = (response) => {
            const Responsedata = response.data.data;
            setTransactionsList({ ...TransactionsList, data: Responsedata.allPaymentRegister, totalSum: Responsedata.totalSum });
        }
        const OnDataFailed = () => {
            setTransactionsList({ ...TransactionsList, data: [], totalSum: 0 ,numericvalue:0});
        }
        datacontext.getFilterData(OnDataRecived, OnDataFailed, complementaryurl, true);
    }

    const getTransactionDetail = (complementaryurl) =>{
        const OnDataTransactionDetails = (response) => {
            const Responsedata = response.data.data;
            const data =(Responsedata?.responseTransactionDetails)?Responsedata?.responseTransactionDetails:Responsedata
            const totalSum = (Responsedata?.totalRecords)?Responsedata?.totalRecords:Responsedata.length
            setTransactionDetailList({ ...TransactionDetailsList, data: data ,totalSum: totalSum})
     
        }
        const OnDataTransactionFailed = () => {
            setTransactionDetailList({ ...TransactionDetailsList, data: [], totalSum: 0 })
        }
        datacontext.getTransactionDetails(OnDataTransactionDetails, OnDataTransactionFailed, complementaryurl,true);
    }
    
    const getTimeCharts =(complementaryurl)=>{
       const OnDataDepositRecived = (response) => {
           const Responsedata = response.data.data;
           if (Responsedata.hourSums.length>0) {
               const formattedData = Responsedata.hourSums.map(item => ({
                   time: item.hour,
                   amount: item.sum
               }));
           
               formattedData.sort((a, b) => a.time - b.time);
               setSellTimeChartData(formattedData);
           }
           if (Responsedata.monthSums.length > 0) {
               const formattedData = Responsedata.monthSums.map(item => ({
                   time: `${item.year}-${String(item.monthNumber).padStart(2, '0')}`, // Convertir a formato ISO YYYY-MM
                   amount: item.sum
               }));
               
               formattedData.sort((a, b) => new Date(a.time) - new Date(b.time));
               setSellTimeChartData(formattedData);
           }
           if (Responsedata.daySums.length > 0) {
               const formattedData = Responsedata.daySums.map(item => ({
                   time: item.date,
                   amount: item.sum
               }));
           
               formattedData.sort((a, b) => new Date(a.time) - new Date(b.time));
               setSellTimeChartData(formattedData);
           }
           
   
       }
       const OnDataDepositFailed = (response) => {
   
       }
       datacontext.getSumData(OnDataDepositRecived, OnDataDepositFailed, complementaryurl);
      }
    const getTerminals =(complementaryurl)=>{
           const OnDataTerminals = (response) => {
    
               const Responsedata = response.data.data;
               const dataObject = Responsedata.allTerminalRegister.reduce((acc, item) => {
                   acc[item.terminal] = item.total;
                   return acc;
               }, {});
               setTerminalObject(dataObject);
        
           }
           const OnDataTerminalsFailed = (response) => {
               setTerminalObject({});
           }
           datacontext.getTerminals(OnDataTerminals, OnDataTerminalsFailed, complementaryurl);
        
       }
    const getSums =(complementaryurl)=>{
           const onSumsRecibed =(response)=>{
               const Responsedata = response.data.data;
               setDashboardAmounts((prev) =>( 
                   {Deposits:{ ...prev.Deposits, Value: Responsedata.totalSum.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' }) ,numericvalue:Responsedata.totalSum},
                  ServiceCharge:{...prev.ServiceCharge, Value:Responsedata.totalServicharge.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' }) ,numericvalue:Responsedata.totalServicharge},
                  SatQAmount:{ ...prev.SatQAmount, Value: Responsedata.satqPay.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' }) ,numericvalue:Responsedata.satqPay },
                  TaxesDRAmount:{ ...prev.TaxesDRAmount, Value: Responsedata.commissionDR.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' }) ,numericvalue:Responsedata.commissionDR},
                  Income:{...prev.Income, Value:Responsedata.grossProfit.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' }) ,numericvalue:Responsedata.grossProfit },
                  Transactions:{ ...prev.Transactions, Value: Responsedata.totalTransactions ,numericvalue:Responsedata.totalTransactions},
                  NumberofPersons:{ ...prev.NumberofPersons, Value: Responsedata.totalPerson ,numericvalue:Responsedata.totalPerson},
                  Taxes:{  ...prev.Taxes, Value:Responsedata.satqCharge.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' }) ,numericvalue:Responsedata.satqCharge}}))
           }
           const onSumsFailed=()=>{
       
               setDashboardAmounts((prev) =>( 
                   {Deposits:{ ...prev.Deposits, Value: "$-",numericvalue :0 },
                  ServiceCharge:{...prev.ServiceCharge, Value: "$-",numericvalue :0 },
                  SatQAmount:{ ...prev.SatQAmount, Value: "$-",numericvalue :0 },
                  TaxesDRAmount:{ ...prev.TaxesDRAmount, Value: "$-",numericvalue :0 },
                  Income:{...prev.Income, Value: "$-",numericvalue :0 },
                  Transactions:{ ...prev.Transactions, Value: "-",numericvalue :0},
                  NumberofPersons:{ ...prev.NumberofPersons, Value: "-",numericvalue :0},
                  Taxes:{  ...prev.Taxes, Value: "$-",numericvalue :0}}))
           }
           datacontext.getSums(onSumsRecibed, onSumsFailed, complementaryurl);
       }
      
       const getPhysicalCompleted =(complementaryurl)=>{
        const onPhysicalRecordCompletedRecived = (response) => {
            const Responsedata = response.data.data;
            if (Responsedata) {
                setPaymentsVoucherCompleted({ ...PayementsVouchersCompleted, data: Responsedata, totalSum: Responsedata.length});
            }
        }
        const OnPhysicalRecordCompletedFailed = (response) => {
            setPaymentsVoucherCompleted({ ...PayementsVouchersCompleted, data: [], totalSum: 0 });
        }
        datacontext.getPhysicalPaymentRecordCompleted(onPhysicalRecordCompletedRecived,OnPhysicalRecordCompletedFailed,complementaryurl);
    }

    const getPhysicalRecord =()=>{
        const onPhysicalRecordRecived = (response) => {
            const Responsedata = response.data.data;
            if (Responsedata) {
                setPaymentsVoucher({ ...PayementsVouchers, data: Responsedata, totalSum: Responsedata.length});
            }
        }
        const OnPhysicalRecordFailed = (response) => {
        setPaymentsVoucher({ ...PayementsVouchers, data: [], totalSum: 0 });
       }
        datacontext.getPhysicalPaymentRecord(onPhysicalRecordRecived,OnPhysicalRecordFailed);
    }
   
       const MakeUrl =()=>{
           let complementaryurl2 = ""
           let complementaryurl3 = ""
           let complementaryurl4 = ""
           if (filter.includes("today")) {
               setTableId("Hora");
               complementaryurl2 = "?StartDate=" + getCurrentDate() + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59&filter=H"
               complementaryurl3 = "?StartDate=" + getCurrentDate() + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59&Terminals=1"
               complementaryurl4 = "?StartDate=" + getCurrentDate() + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59"
           }
           else if (filter.includes("month")) {
            setTableId("Dia");

               complementaryurl2 = "?StartDate=" + +year() + "/" + month() + "/01" + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59&filter=D"
               complementaryurl3 = "?StartDate=" + +year() + "/" + month() + "/01" + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59&Terminals=1"
               complementaryurl4 = "?StartDate=" + +year() + "/" + month() + "/01" + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59"
           }
           else if (filter.includes("year")) {
            setTableId("Mes");
               complementaryurl2 = "?StartDate=" + year() + "/01/01" + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59&filter=M"
               complementaryurl3 = "?StartDate=" + year() + "/01/01" + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59&Terminals=1"
               complementaryurl4 = "?StartDate=" + year() + "/01/01" + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59"
           }  
           else {      

            if (specificfilter == null) return;
            if (specificfilter.type.includes("H"))  setTableId("Hora");
            if (specificfilter.type.includes("D"))  setTableId("Dia");
            if (specificfilter.type.includes("M"))  setTableId("Mes");
            if (specificfilter.type.includes("Y"))  setTableId("Año");  

               complementaryurl2 = specificfilter.url + "&filter=" + specificfilter.type
               complementaryurl3 = specificfilter.url + "&Terminals=1"
               complementaryurl4 = specificfilter.url
           }
            
        setDates({
            StartDate: complementaryurl4.split("StartDate=")[1].split("&")[0],
            EndDate: complementaryurl4.split("EndDate=")[1].split("&")[0]
        })
           return ({complementaryurl2,complementaryurl3,complementaryurl4})
       }
   
   
        useEffect(() => {
            if (hasInitialized.current) {
                makeRequest();
            }
        }, [filter, specificfilter])
    
        useEffect(() => {
            if (!hasInitialized.current&&!hasRealyInitialized.current) {
                setTimeout(()=>{
                    hasInitialized.current = true;
                },500)
                hasRealyInitialized.current = true;
                makeRequest();
            }
        }, [])
       const makeRequest = () => {
           ResetData();
           const {complementaryurl2,complementaryurl3,complementaryurl4} =MakeUrl();
           getTimeCharts(complementaryurl2);
           getTerminals(complementaryurl3);
           getSums(complementaryurl4);
           getTransactions(complementaryurl4);
           getTransactionDetail(complementaryurl4);
           getPhysicalCompleted(complementaryurl4);
           getPhysicalRecord();
       }
       return({
        SellTimeChart,
        TransactionsList,
        TerminalObj,
        Dates,
        TransactionDetailsList,
        TableID,
        DashboardAmounts,
        PayementsVouchersCompleted,
        PayementsVouchers
       })
}
export default useContentReports;
