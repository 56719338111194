import { useEffect, useState,useRef } from "react"
import { useContext } from "react";
import { Context } from "../../../../../../context/Context";
import { getCurrentDate , month, year} from "../../../../../../data/Datos";


 
const useContentDashboard = ({ filter = "today", specificfilter = null }) => {
     
    const datacontext = useContext(Context);
    const hasInitialized = useRef(false);
    const hasRealyInitialized = useRef(false);

    const [DashboardAmounts, setDashboardAmounts] = useState({
        Deposits:{ Title: "Total de ingresos", Value: "$-", SecundaryValue: "Cantidad de ingresos totales" ,numericvalue :0 },
        ServiceCharge:{ Title: "Service Charge", Value: "$-", SecundaryValue: "Total cargos por servicio", numericvalue :0 },
        SatQAmount:{ Title: "Pago a SATQ", Value: "$-", SecundaryValue: "1.88 UMA'S", numericvalue :0 },
        TaxesDRAmount:{ Title: "Comisión DR", Value: "$-", SecundaryValue: "0.72 UMA'S", numericvalue :0 },
        Income:{ Title: "Utilidad Bruta", Value: "$-", SecundaryValue: "Comisión DR + Service Charge",numericvalue :0 },
        Transactions:{ Title: "Transacciones", Value: "-", SecundaryValue: "Total de transacciones" ,numericvalue :0},
        NumberofPersons:{ Title: "PAX", Value: "-", SecundaryValue: "Total de personas" ,numericvalue :0},
        Taxes:{ Title: "Cobro a SATQ", Value: "-", SecundaryValue: "2.5 UMAS",numericvalue :0 },
     }
    );
    const [SellTimeChart, setSellTimeChartData] = useState([]);
    const [TransactionsUsersOBJ, setTransactionsUsers] = useState({});
    const [TerminalObj, setTerminalObject] = useState({});
   
    const ResetData = ()=>{
        setSellTimeChartData([])
        setTransactionsUsers({});
        setTerminalObject({});
        setDashboardAmounts((prev) =>( 
            {Deposits:{ ...prev.Deposits, Value: "$-",numericvalue :0 },
           ServiceCharge:{...prev.ServiceCharge, Value: "$-",numericvalue :0 },
           SatQAmount:{ ...prev.SatQAmount, Value: "$-",numericvalue :0 },
           TaxesDRAmount:{ ...prev.TaxesDRAmount, Value: "$-",numericvalue :0 },
           Income:{...prev.Income, Value: "$-",numericvalue :0 },
           Transactions:{ ...prev.Transactions, Value: "-",numericvalue :0},
           NumberofPersons:{ ...prev.NumberofPersons, Value: "-",numericvalue :0},
           Taxes:{  ...prev.Taxes, Value: "$-",numericvalue :0}}))


    }
   const getTimeCharts =(complementaryurl)=>{
    const OnDataDepositRecived = (response) => {
 
        const Responsedata = response.data.data;
 
        if (Responsedata.hourSums.length>0) {
            const formattedData = Responsedata.hourSums.map(item => ({
                time: item.hour,
                amount: item.sum
            }));
        
            formattedData.sort((a, b) => a.time - b.time);
            setSellTimeChartData(formattedData);
        }
        if (Responsedata.monthSums.length > 0) {
            const formattedData = Responsedata.monthSums.map(item => ({
                time: `${item.year}-${String(item.monthNumber).padStart(2, '0')}`, // Convertir a formato ISO YYYY-MM
                amount: item.sum
            }));
            
            formattedData.sort((a, b) => new Date(a.time) - new Date(b.time));
            setSellTimeChartData(formattedData);
        }
        if (Responsedata.daySums.length > 0) {
            const formattedData = Responsedata.daySums.map(item => ({
                time: item.date,
                amount: item.sum
            }));
        
            formattedData.sort((a, b) => new Date(a.time) - new Date(b.time));
            setSellTimeChartData(formattedData);
        }
        

    }
    const OnDataDepositFailed = (response) => {

    }
    datacontext.getSumData(OnDataDepositRecived, OnDataDepositFailed, complementaryurl);
   }
    const getTransactionsUser =(complementaryurl)=>{
        const OnTransactionsUsersRecived = (response) => {
            const Responsedata = response.data.data;
    
            setTransactionsUsers(Responsedata);
        }
        const OnTransactionsUsersFailed = (response) => {
            setTransactionsUsers([]);
        }
        datacontext.getTransactionUserRecords(OnTransactionsUsersRecived, OnTransactionsUsersFailed,complementaryurl);
    }

    const getTerminals =(complementaryurl)=>{
        const OnDataTerminals = (response) => {
 
            const Responsedata = response.data.data;
            const dataObject = Responsedata.allTerminalRegister.reduce((acc, item) => {
                acc[item.terminal] = item.total;
                return acc;
            }, {});
            setTerminalObject(dataObject);
     
        }
        const OnDataTerminalsFailed = (response) => {
            setTerminalObject({});
        }
        datacontext.getTerminals(OnDataTerminals, OnDataTerminalsFailed, complementaryurl);
     
    }

    const getSums =(complementaryurl)=>{
        const onSumsRecibed =(response)=>{
            const Responsedata = response.data.data;
            setDashboardAmounts((prev) =>( 
                {Deposits:{ ...prev.Deposits, Value: Responsedata.totalSum.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' }) ,numericvalue:Responsedata.totalSum},
               ServiceCharge:{...prev.ServiceCharge, Value:Responsedata.totalServicharge.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' }) ,numericvalue:Responsedata.totalServicharge},
               SatQAmount:{ ...prev.SatQAmount, Value: Responsedata.satqPay.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' }) ,numericvalue:Responsedata.satqPay },
               TaxesDRAmount:{ ...prev.TaxesDRAmount, Value: Responsedata.commissionDR.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' }) ,numericvalue:Responsedata.commissionDR},
               Income:{...prev.Income, Value:Responsedata.grossProfit.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' }) ,numericvalue:Responsedata.grossProfit },
               Transactions:{ ...prev.Transactions, Value: Responsedata.totalTransactions ,numericvalue:Responsedata.totalTransactions},
               NumberofPersons:{ ...prev.NumberofPersons, Value: Responsedata.totalPerson ,numericvalue:Responsedata.totalPerson},
               Taxes:{  ...prev.Taxes, Value:Responsedata.satqCharge.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' }) ,numericvalue:Responsedata.satqCharge}}))
        }
        const onSumsFailed=()=>{
    
            setDashboardAmounts((prev) =>( 
                {Deposits:{ ...prev.Deposits, Value: "$-",numericvalue :0 },
               ServiceCharge:{...prev.ServiceCharge, Value: "$-",numericvalue :0 },
               SatQAmount:{ ...prev.SatQAmount, Value: "$-",numericvalue :0 },
               TaxesDRAmount:{ ...prev.TaxesDRAmount, Value: "$-",numericvalue :0 },
               Income:{...prev.Income, Value: "$-",numericvalue :0 },
               Transactions:{ ...prev.Transactions, Value: "-",numericvalue :0},
               NumberofPersons:{ ...prev.NumberofPersons, Value: "-",numericvalue :0},
               Taxes:{  ...prev.Taxes, Value: "$-",numericvalue :0}}))
        }
        datacontext.getSums(onSumsRecibed, onSumsFailed, complementaryurl);
    }
   


    const MakeUrl =()=>{
        let complementaryurl2 = ""
        let complementaryurl3 = ""
        let complementaryurl4 = ""
        if (filter.includes("today")) {
            complementaryurl2 = "?StartDate=" + getCurrentDate() + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59&filter=H"
            complementaryurl3 = "?StartDate=" + getCurrentDate() + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59&Terminals=1"
            complementaryurl4 = "?StartDate=" + getCurrentDate() + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59"
        }
        else if (filter.includes("month")) {
            complementaryurl2 = "?StartDate=" + +year() + "/" + month() + "/01" + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59&filter=D"
            complementaryurl3 = "?StartDate=" + +year() + "/" + month() + "/01" + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59&Terminals=1"
            complementaryurl4 = "?StartDate=" + +year() + "/" + month() + "/01" + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59"
        }
        else if (filter.includes("year")) {
            complementaryurl2 = "?StartDate=" + year() + "/01/01" + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59&filter=M"
            complementaryurl3 = "?StartDate=" + year() + "/01/01" + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59&Terminals=1"
            complementaryurl4 = "?StartDate=" + year() + "/01/01" + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59"
        }  
        else {      
            complementaryurl2 = specificfilter.url + "&filter=" + specificfilter.type
            complementaryurl3 = specificfilter.url + "&Terminals=1"
            complementaryurl4 = specificfilter.url
        }
        return ({complementaryurl2,complementaryurl3,complementaryurl4})
    }


 
    const makeRequest = () => {
        ResetData();
        const {complementaryurl2,complementaryurl3,complementaryurl4} =MakeUrl();
        getTimeCharts(complementaryurl2);
        getTerminals(complementaryurl3);
        getTransactionsUser(complementaryurl4);
        getSums(complementaryurl4);
    }

    useEffect(() => {
        if (hasInitialized.current) {
            makeRequest();
        }
    }, [filter, specificfilter])

    useEffect(() => {
        if (!hasInitialized.current&&!hasRealyInitialized.current) {
            setTimeout(()=>{
                hasInitialized.current = true;
            },500)
            hasRealyInitialized.current = true;
            makeRequest();
        }
    }, [])
 

 
    return {   
        SellTimeChart,
        TerminalObj,
        TransactionsUsersOBJ,
        DashboardAmounts,
        makeRequest };

 
}
export default useContentDashboard;